import React from "react";
import { useField } from "formik";

const Select = ({ ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and alse replace ErrorMessage entirely.
  const [field] = useField(props);
  return (
    <>
      <select type="checkbox" {...field} {...props} />
    </>
  );
};

export default Select;
