import React from "react";

import "./PurchaseCard.css";
import PurchaseCard from "./PurchaseCard";

// const categoriesId = [
//   { name: 'Productos', value: '1' },
//   { name: 'Viajes', value: '2' },
//   { name: 'Entretenimiento', value: '3' },
//   { name: 'Gastronomia', value: '4' },
//   { name: 'Eventos', value: '5' },
//   { name: 'Imperdibles', value: '6' },
// ]

const PurchasesContainer = (props) => {
  const buysFiltered = (stateId) => {
    let arrayProductsId = [];
    let filtered = true;

    /// RECORRER LOS PRODUCTS Y OBTENER TODOS LOS IDS DE PRODUCTOS QUE TIENEN EL STATUS QUE QUIERO
    props.products.forEach((item) => {
      if (stateId === "Activo") {
        // ACTIVOS
        if (item.status === "active") {
          if (props.categoryId !== "Todas") {
            if (props.categoryId ===  item.category ){
              arrayProductsId.push(item.id);
            }
          } else {
            arrayProductsId.push(item.id);
          }
        }
      } else if (stateId === "Rifado") {
        if (item.status === "winned" && item.winner !== "") {
          if (props.categoryId !== "Todas") {
            if (props.categoryId ===  item.category )
             {
              arrayProductsId.push(item.id);
            }
          } else {
            arrayProductsId.push(item.id);
          }
        }
      } else if (stateId === "Finalizado") {
        if (item.status === "closed" && item.winner === "") {
          if (props.categoryId !== "Todas") {
            if ( props.categoryId ===  item.category )
             {
              arrayProductsId.push(item.id);
            }
          } else {
            arrayProductsId.push(item.id);
          }
        }
      } else {
        if (props.categoryId !== "Todas") {
          if ( props.categoryId ===  item.category ){
            arrayProductsId.push(item.id);
          }
        } else {
          filtered = false;
        }
      }
    });

    /// RECORRER MIS COMPRAS Y COMPARAR CON LOS IDS SELECCIONADOS PREVIAMENTE
    let buysJsonFiltered = [];

    if (filtered === false) {
      buysJsonFiltered = props.purchases;
    } else {
      for (let index = 0; index < arrayProductsId.length; index++) {
        for (let index2 = 0; index2 < props.purchases.length; index2++) {
          if (props.purchases[index2].idproduct === arrayProductsId[index]) {
            buysJsonFiltered.push(props.purchases[index2]);
          }
        }
      }
    }

    /// EXPORTAR EL MIS COMPRAS FILTRADO
    return buysJsonFiltered;
  };
  
  return (
    <div>
      {buysFiltered(props.stateId).map((item) => (
        <PurchaseCard key={item.id} item={item} products={props.products} />
      ))}
    </div>
  );
};

export default PurchasesContainer;
