import React, { useContext, useState, useEffect, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";

import { AuthContext } from "../../context/AuthContext";
import { CartContext } from "../../context/CartContext";
//import { SearchContext } from "../../context/SearchContext";
import { NotificationContext } from "../../context/NotificationContext";

import { useHttpClient } from "../../hooks/http-hook";

import "./MainNavigation.css";

import AuthModal from "../../../user/components/AuthModal";
import UserUpdateModal from "../../../user/components/UserUpdateModal";

import CartProductsContainerNav from "./CartProductsContainerNav";
import NotificationContainerNav from "./NotificationContainerNav";
import Avatar from "../UIElements/Avatar";

import { BiSearch, BiCart } from "react-icons/bi";
import { HiOutlineBell } from "react-icons/hi";

import bigtimelogo from "../../assets/logo-small.png";

import { InstantSearch, SearchBox } from "react-instantsearch-dom";

const MainNavigation = (props) => {
  const auth = useContext(AuthContext);
  const history = useHistory();

  const { itemCount } = useContext(CartContext);
  const { notReadedNotifications, setNotReadedNotifications } = useContext(NotificationContext);

  const { sendRequest } = useHttpClient();

  // const { searchClient, index, CustomHits, setResults } =
  //   useContext(SearchContext);

  const [showAuth, setShowAuth] = useState(false);
  const [showUserUpdate, setShowUserUpdate] = useState(false);
  const [loggedUser, setLoggedUser] = useState(null);
  const [isModalLogin, setIsModalLogin] = useState(null);
  const [loadedCategories, setLoadedCategories] = useState();

  const handleCloseAuth = () => setShowAuth(false);

  // const handleChange = (e) => {
  //   history.push("/products");
  //   const { value } = e.target;

  //   value === "" ? setResults(null) : setResults(value);
  // };

  const handleUserUpdate = useCallback(
    () => setShowUserUpdate(!showUserUpdate),
    [showUserUpdate]
  );

  const handleShowLogin = () => {
    setIsModalLogin(true);
    setShowAuth(true);
  };

  const handleShowSignup = () => {
    setIsModalLogin(false);
    setShowAuth(true);
  };

  useEffect(() => {
    setLoggedUser(auth.loggedUserData);
  }, [auth.loggedUserData]);

  useEffect(() => {
    setLoggedUser(auth.loggedUserData);

    const fetchCategories = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_API_URL}/api/categories`
        );
        setLoadedCategories(responseData.categories);
      } catch (err) {}
    };

    fetchCategories();
  }, [sendRequest, auth.loggedUserData]);

  const handleNotifications = async () => {
    try {
      await sendRequest(
        `${process.env.REACT_APP_API_URL}/api/notifications/isReadNotification/${auth.userId}`,
        "PATCH",
        null,
        { Authorization: "Bearer " + auth.token }
      );
      setNotReadedNotifications([]);

    } catch (err) {}
  };

  return (
    <React.Fragment>
      <AuthModal
        show={showAuth}
        onHide={handleCloseAuth}
        isModalLogin={isModalLogin}
        title={isModalLogin ? "Iniciá Sesión" : "¡Registrate!"}
        setIsModalLogin={setIsModalLogin}
      />
      <UserUpdateModal
        {...loggedUser}
        show={showUserUpdate}
        onHide={handleUserUpdate}
      />
      <Navbar collapseOnSelect className="main-navbar" expand="lg">
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Brand as={Link} to="/" className="navbar__logo">
          <img src={bigtimelogo} alt="BigTime" />
        </Navbar.Brand>

        <div className="d-flex align-items-center">
          <div className="navbar__cart-icon-counter">{itemCount}</div>
          <NavDropdown
            alignRight
            title={<BiCart className="navbar__cart-icon" />}
            id="navbar__cart-dropdown-right"
            className="navbar__dropdown navbar__dropdown-cart-container--collapsed"
          >
            <CartProductsContainerNav />
          </NavDropdown>
        </div>
        <Navbar.Collapse id="navbarScroll" className="navbar__center-content">
          <Nav>
            <NavDropdown
              className="navbar__dropdown navbar__dropdown-categories"
              title="Categorías"
            >
              {loadedCategories &&
                loadedCategories.map((items, index) => {
                  return (
                    <NavDropdown.Item
                      className={`navbar__dropdown-item navbar__dropdown-item--${items.title}`}
                      as={Link}
                      to={{
                        pathname: "/products",
                        state: {
                          categoryRoute: items.title,
                          priceRoute: "Desde $0",
                          progressRoute: "Desde 0%",
                        },
                      }}
                      eventKey={index + 1}
                      key={index + 1}
                    >
                      {items.title}
                    </NavDropdown.Item>
                  );
                })}
            </NavDropdown>
            <Nav.Link as={Link} to="/help" eventKey="4">
              Ayuda
            </Nav.Link>
          </Nav>
          <div>
            {/* <InstantSearch searchClient={searchClient} indexName={index}>
              <SearchBox
                translations={{
                  placeholder: "Viajes, Cenas, Entradas",
                }}
                onChange={handleChange}
              >
                <BiSearch className="navbar__search-icon" />
              </SearchBox>

              <CustomHits />
            </InstantSearch> */}
          </div>
          {!auth.isLoggedIn && (
            <Nav>
              <Nav.Link
                onClick={handleShowSignup}
                className="navbar__link navbar__link--small"
                eventKey="5"
              >
                Creá tu Cuenta
              </Nav.Link>
              <Nav.Link
                onClick={handleShowLogin}
                className="navbar__link navbar__link--small"
                eventKey="6"
              >
                Ingresá
              </Nav.Link>
            </Nav>
          )}
          {auth.isLoggedIn && (
            <Nav>
              <NavDropdown
                title={
                  <div className="navbar__profile-container">
                    <Avatar
                      image={
                        loggedUser &&
                        `${process.env.REACT_APP_API_URL}/${loggedUser.image}`
                      }
                      alt="default avatar"
                      className="avatar--bordered"
                      style={{
                        marginRight: "8px",
                        width: "24px",
                        height: "24px",
                      }}
                    ></Avatar>
                    {loggedUser && loggedUser.firstName}
                  </div>
                }
                id="navbar__profile-dropdown"
                className="navbar__dropdown navbar__profile-dropdown"
              >
                <div className="navbar__profile-dropdown-info">
                  <Avatar
                    image={
                      loggedUser &&
                      `${process.env.REACT_APP_API_URL}/${loggedUser.image}`
                    }
                    alt="default avatar"
                    style={{
                      margin: "0px 8px",
                      width: "36px",
                      height: "36px",
                    }}
                  ></Avatar>
                  <div>
                    <div className="navbar__profile-dropdown-greeting">
                      Hola {loggedUser && loggedUser.firstName}
                    </div>
                    <div className="navbar__profile-dropdown-email">
                      {loggedUser && loggedUser.email}
                    </div>
                  </div>
                </div>
                <div className="navbar__profile-dropdown-separator">
                  <NavDropdown.Item
                    onClick={handleUserUpdate}
                    className="navbar__dropdown-link--grey"
                    eventKey="10"
                  >
                    Mis Datos
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className="navbar__dropdown-link--grey"
                    eventKey="11"
                    as={Link}
                    to={`/users/${auth.userId}/purchases`}
                  >
                    Mis Compras
                  </NavDropdown.Item>
                  {loggedUser &&
                    (loggedUser.type === "admin" ||
                      loggedUser.type === "superadmin") && (
                      <NavDropdown.Item
                        as={Link}
                        className="navbar__dropdown-link--grey"
                        eventKey="15"
                        to="/backend"
                      >
                        Backend
                      </NavDropdown.Item>
                    )}
                </div>

                <NavDropdown.Item
                  className="navbar__dropdown-link--grey"
                  onClick={auth.logout}
                  as={Link}
                  to="/"
                  eventKey="7"
                >
                  Cerrar Sesión
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link
                as={Link}
                to={`/users/${auth.userId}/purchases`}
                className="navbar__link navbar__link--small"
                eventKey="8"
              >
                Mis Compras
              </Nav.Link>
              { notReadedNotifications.length > 0 && (
                <div className="navbar__notifications-icon-counter">
                  {notReadedNotifications.length}
                </div>
              )}
              <NavDropdown
                alignRight
                title={<HiOutlineBell className="navbar__notifications-icon" />}
                className="navbar__dropdown navbar__dropdown-notifications-container"
                onClick={handleNotifications}
              >
                <NotificationContainerNav />
              </NavDropdown>
            </Nav>
          )}
        </Navbar.Collapse>

        <NavDropdown
          alignRight
          title={<BiCart className="navbar__cart-icon" />}
          id="navbar__cart-dropdown-right"
          className="navbar__dropdown navbar__dropdown-cart-container"
        >
          <CartProductsContainerNav />
        </NavDropdown>
      </Navbar>
    </React.Fragment>
  );
};

export default MainNavigation;
