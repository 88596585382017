import React, { useContext, useEffect, useState } from "react";

import UsersList from "../components/UsersList";
import GenericModal from "../../../shared/components/UIElements/GenericModal";
import LoadingSpinner from "../../../shared/components/UIElements/LoadingSpinner";
import BannerCategory from "../../../shared/components/UIElements/BannerCategory";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import { AuthContext } from "../../../shared/context/AuthContext";

const Users = () => {
    const auth = useContext(AuthContext);
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const [loadedUsers, setLoadedUsers] = useState();
    const [loggedUser, setLoggedUser] = useState(null);
    const [userrefresh, setUsersRefresh] = useState(false);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const responseData = await sendRequest(
                    `${process.env.REACT_APP_API_URL}/api/users`,
                    "GET",
                    null,
                    { Authorization: "Bearer " + auth.token }
                );
                setLoadedUsers(responseData.users);
                setUsersRefresh(false);
            } catch (err) {}
        };
        fetchUsers();
    }, [sendRequest, userrefresh, auth.token]);

    useEffect(() => {
        const fetchUser = async () => {
            if (auth.userId) {
                try {
                    const responseData = await sendRequest(
                        `${process.env.REACT_APP_API_URL}/api/users/${auth.userId}`,
                        "GET",
                        null,
                        { Authorization: "Bearer " + auth.token }
                    );
                    setLoggedUser(responseData.user);
                } catch (err) {}
            } else {
                setLoggedUser(null);
            }
        };
        fetchUser();
    }, [auth.login, auth.logout, auth.userId, auth.token, sendRequest]);

    return (
        <React.Fragment>
            <GenericModal
                message={error}
                show={!!error}
                error={true}
                title={"Ocurrió un error"}
                onClear={clearError}
            />
            {isLoading && (
                <div className="center">
                    <LoadingSpinner />
                </div>
            )}
            <BannerCategory title="Administración de Usuarios" />
            {!isLoading && loadedUsers && (
                <UsersList
                    userslist={loadedUsers}
                    user={loggedUser}
                    refresh={setUsersRefresh}
                />
            )}
        </React.Fragment>
    );
};

export default Users;
