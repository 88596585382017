import React from "react";

import Counter from "./Counter"
import "./UserCounterSection.css";


const UserCounterSection = (props) => {
  return (
    <div className="user-counter-section__container">
     <Counter {...props}/>
    </div>
  );
};

export default UserCounterSection;
