import React, { useContext } from "react";

import { CartContext } from "../../shared/context/CartContext";

import CartItem from "./CartItem";

import "./CartProductsContainer.css";

const CartProductsContainer = () => {
    const { cartItems } = useContext(CartContext);

    return (
        <div className="cart-page__products">
            {cartItems.length > 0 &&
                cartItems.map((product) => (
                    <CartItem key={product.id} id={product.id} product={product} />
                ))}
        </div>
    );
};

export default CartProductsContainer;
