import React from "react";
import { useField } from "formik";

const Switch = ({ ...props }) => {
  const [field] = useField(props);
  return (
    <div className="custom-control custom-switch">
      <input
        {...field}
        {...props}
        className="form-check-input"
        type="checkbox"
      />
      <span className="slider" />
    </div>
  );
};

export default Switch;
