import React from "react";
import { useState } from "react";
import { Carousel } from "react-bootstrap";

import "./ProductImageCarrusel.css";

// const DUMMY_PRODUCT_EXAMPLE = {
//     id: "1",
//     title: "Titulo de producto",
//     category: "Categoría de producto",
//     description: "Descripción de producto",
//     imageUrl: "Espera una URL, a cambiar o definir luego",
//     price: "precio",
//     tickets: "número de tickets en Integer",
//     maxTickets: "número máximo de tickets en Integer",
//     status: "active o inactive dependiendo de si está completado o no",
// };



const ProductImageCarrusel = (props) => {

 
    const [index, setIndex] = useState(0);
  
    const handleSelect = (selectedIndex, e) => {
      setIndex(selectedIndex);
    };

  return (
    <div className="product-image-carrusel__container">
    <Carousel activeIndex={index} onSelect={handleSelect} indicators={true} className="product-image-carrusel" controls={false}>
        
        {props.images.map((item, index) => (
          <Carousel.Item key={index} interval={4500}><img src={`${process.env.REACT_APP_API_URL}/${item}`} className="product-image-carrusel__image" alt={props.title} />
          </Carousel.Item>
        ))}
    </Carousel>
    </div>
  );


};

export default ProductImageCarrusel;

