import React, { useEffect, useState, useContext } from "react";

import BannerCategory from "../../../shared/components/UIElements/BannerCategory";
import ProductList from "../components/ProductList";
import GenericModal from "../../../shared/components/UIElements/GenericModal";
import LoadingSpinner from "../../../shared/components/UIElements/LoadingSpinner";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import { AuthContext } from "../../../shared/context/AuthContext";

const ProductListPage = () => {
    const auth = useContext(AuthContext);
    const [loadedProducts, setLoadedProducts] = useState();
    const { isLoading, error, sendRequest, clearError } = useHttpClient();

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const responseData = await sendRequest(
                    `${process.env.REACT_APP_API_URL}/api/products/all`,
                    "GET",
                    null,
                    { Authorization: "Bearer " + auth.token }
                );
                setLoadedProducts(responseData.products);
            } catch (err) {}
        };
        fetchProducts();
    }, [sendRequest, auth.token]);

    const productDeletedHandler = (deletedProductId) => {
        setLoadedProducts((prevProduct) =>
            prevProduct.filter((product) => product.id !== deletedProductId)
        );
    };
    return (
        <React.Fragment>
            <GenericModal
                message={error}
                show={!!error}
                error={true}
                title={"Ocurrió un error"}
                onClear={clearError}
            />
            {isLoading && <LoadingSpinner />}
            <BannerCategory title="Administración de Productos" />
            {!isLoading && loadedProducts && (
                <ProductList
                    items={loadedProducts}
                    onDeleteProduct={productDeletedHandler}
                />
            )}
        </React.Fragment>
    );
};

export default ProductListPage;
