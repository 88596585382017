import { React, useContext, useCallback, useEffect, useState } from "react";

import { Link } from "react-router-dom";

import { FaExclamationCircle } from "react-icons/fa";

import { AuthContext } from "../../shared/context/AuthContext";
import { useHttpClient } from "../../shared/hooks/http-hook";

import "./CheckoutPage.css";

const CheckoutPage = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let status = params.get("status");
    let payment_id = params.get("payment_id");
    let external_reference = params.get("external_reference");
    let payment_type = params.get("payment_type");

    const paymentMethods = {
        credit_card: "tarjeta de crédito",
        debit_card: "tarjeta de débito",
        ticket: "Rapipago/Pago Fácil",
    };

    const [order, setOrder] = useState(null);

    const { sendRequest } = useHttpClient();

    const auth = useContext(AuthContext);

    const orderFetch = useCallback(async () => {
        try {
            const responseData = await sendRequest(
                `${process.env.REACT_APP_API_URL}/api/orders/${external_reference}`,
                "GET",
                null,
                { Authorization: "Bearer " + auth.token }
            );
            setOrder(responseData.order);
        } catch (err) {}
    }, [sendRequest, auth.userId, auth.token, external_reference]);

    useEffect(() => {
        orderFetch();
    }, [orderFetch]);

    return (
        <div className="checkout-page">
            {status !== "null" && (
                <>
                    <h2 className="checkout-page__title">¡Felicitaciones!</h2>
                    <div className="checkout-page__payment-confirmation">
                        Tu compra fue realizada de manera exitosa <br /> Pagaste{" "}
                        <div className="checkout-page__payment-amount">
                            ${order && order.total}
                        </div>{" "}
                        mediante {paymentMethods[payment_type]}.
                    </div>
                    <div className="checkout-page_payment-code-header">
                        El código de tu compra es:
                    </div>
                    <div className="checkout-page__payment-code">
                        {payment_id}
                    </div>
                    <Link
                        to="/"
                        className="checkout-page__purchase-details-link"
                    >
                        Descargar detalle de mi compra
                    </Link>
                    <div className="checkout-page__information">
                        <div className="checkout-page__information-icon">
                            <FaExclamationCircle />
                        </div>
                        <div className="checkout-page__information-text">
                            Una vez completado el total de candidatos
                            interesados en el premio, el ganador se determinará
                            mediante un "sorteo". En el sorteo se elige también
                            a dos suplentes, por lo cual el premio no queda
                            vacante (siempre alguien se lo lleva). Nos
                            comunicamos con el ganador mediante la dirección de
                            mail completado al momento de registrarse, el
                            ganador tiene un período de 15 días para reclamar el
                            premio. Al cabo de los 15 días, si el ganador no
                            reclama el premio, nos comunicamos con el suplente
                            para finalizar el proceso.
                        </div>
                    </div>
                    <div className="checkout-page__footer">
                        ¡Te deseamos mucha suerte!
                    </div>
                    <div className="checkout-page__reminder">
                        (Psss recuerda que puedes participar en tantos sorteos
                        como quieras...)
                    </div>
                </>
            )}
            {status === "null" && order && (
                <>
                    <h2 className="checkout-page__title">
                        Tu pago aún no fue completado
                    </h2>
                    <div className="checkout-page__payment-confirmation">
                        Tu compra por{" "}
                        <div className="checkout-page__payment-amount">
                            ${order && order.total}
                        </div>{" "}
                        no ha podido completarse.
                    </div>
                    <div className="checkout-page_payment-code-header">
                        El código de tu orden en nuestro sitio es:
                    </div>
                    <div className="checkout-page__payment-code">
                        {external_reference}
                    </div>
                    <a
                        href={`${order.preferenceLink}`}
                        className="checkout-page__purchase-details-link"
                    >
                        Click aquí para volver a MercadoPago
                    </a>
                    <div className="checkout-page__fail-reminder">
                        Tenes hasta el **placeholder** para completar la compra
                        en caso de que no puedas hacerlo en este momento. Podes
                        encontrar el link para reanudarla en "Mis Compras"
                        dentro de tu perfil.
                    </div>
                </>
            )}
        </div>
    );
};

export default CheckoutPage;
