import React from "react";

import './PartnersFormText.css'

const PartnersFormText = () =>{

    return(
    <div className="partners__content">
        <div className="partners__content-center">
            <h2 className="partners__title--big"><span className="partners__title--bigcolor">Big</span>Time</h2>
            <h3 className="partners__title">Partners</h3>
            <p className="partners__subcontent--bullet" ></p>
            <p className="partners__subcontent" >Te responderemos a la mayor brevedad posible, pero recuerda que nos puedes escribir a nuestro <span className="partners__sub-title">Whatsapp</span></p>
            <p className="partners__sub-title" >Email</p>
            <p className="partners__subcontent" >partners@bigtime.com.ar</p>
        </div>
    </div>



    );

};

export default PartnersFormText;