import React, { createContext, useState, useEffect, useCallback } from "react";
import { useHttpClient } from "../hooks/http-hook";

export const ProductsContext = createContext();

const ProductsContextProvider = ({ children }) => {
    const [products, setProducts] = useState(null);

    const { sendRequest, isLoading } = useHttpClient();

    const productsFetch = useCallback(async () => {
        try {
            const responseData = await sendRequest(
                `${process.env.REACT_APP_API_URL}/api/products/`
            );
            setProducts(responseData.products);
        } catch (err) {
        }
    }, [sendRequest]);

    useEffect(() => {
        productsFetch();
    }, [productsFetch]);

    return (
        <ProductsContext.Provider value={{ products, productsIsLoading: isLoading }}>
            {children}
        </ProductsContext.Provider>
    );
};

export default ProductsContextProvider;
