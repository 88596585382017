import React, { useContext, useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";

import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import Card from "../../../shared/components/UIElements/Card";
import BannerCategory from "../../../shared/components/UIElements/BannerCategory";
import Select from "../../../shared/components/FormElements/Select";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import { AuthContext } from "../../../shared/context/AuthContext";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import "../components/DraftComponent.css";
import DateTimePicker from 'react-datetime-picker';
// import draftToHtml from 'draftjs-to-html';
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import "./ProductListPage.css";
import Button from "../../../shared/components/FormElements/Button";

const toolbarProps={
  colorPicker: {
    default: 'var(--main-black)',
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    colors: ['var(--main-orange)','var(--secondary-orange)','var(--main-red)','var(--main-black)','var(--grey-1)','var(--grey-2)','var(--grey-3)','var(--btn-purple)','var(--btn-blue)','var(--btn-yellow)'],
  },fontFamily: {
    default: ['Poppins'],
    options: ['Poppins'],
    className: 'sans-serif',
    component: undefined,
    dropdownClassName: undefined,
  }
  }

const HelpSchema = Yup.object().shape({
  title: Yup.string().required("Requerido"),
  // category: Yup.string().required("Requerido"),
  imageUrl: Yup.mixed(),
  price: Yup.string().required("Requerido"),
  shortDescription: Yup.string().required("Requerido"),
  maxTickets: Yup.string().required("Requerido"),
  expirationDate: Yup.string().required("Requerido"),
  status: Yup.string().required("Requerido"),
  show: Yup.string().required("Requerido"),
});

const NewProduct = () => {
  const auth = useContext(AuthContext);
  const { sendRequest, error, clearError } = useHttpClient();
  const [loadedCategories, setLoadedCategories] = useState();

  const [editorStateSummary, setEditorStateSummary] =
    useState(() => EditorState.createEmpty());
  const [editorStateMoreInfo, setEditorStateMoreInfo] = useState(
    () => EditorState.createEmpty()
  );

  const history = useHistory();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_API_URL}/api/categories`
        );
        setLoadedCategories(responseData.categories);
      } catch (err) {}
    };
    fetchCategories();
  }, [sendRequest]);

  return (
    <React.Fragment>
      <BannerCategory title="Crear Producto" />
      <Formik
        initialValues={{
          title: "",
          category: "",
          imageUrl: "",
          price: "",
          tickets: "",
          shortDescription: "",
          maxTickets: "",
          expirationDate: "",
          status: "",
          lotteryDate: "",
          show: false,
        }}
        validateOnMount
        validationSchema={HelpSchema}
        onSubmit={async (values) => {
          clearError();

          try {
            const formData = new FormData();

            formData.append("title", values.title);
            formData.append("category", values.category);

            const summary = JSON.stringify(
              convertToRaw(editorStateSummary.getCurrentContent())
            );
            const moreInfo = JSON.stringify(
              convertToRaw(editorStateMoreInfo.getCurrentContent())
            );
            formData.append("summary", summary);
            formData.append("shortDescription", values.shortDescription);
            formData.append("moreInfo", moreInfo);
            for (let i = 0; i < values.imageUrl.length; i++) {
              formData.append("imageUrl", values.imageUrl[i]);
            }
            formData.append("price", values.price);
            formData.append("tickets", values.tickets);
            formData.append("maxTickets", values.maxTickets);
            formData.append("expirationDate", values.expirationDate);
            formData.append("status", values.status);
            formData.append("lotteryDate", values.lotteryDate);
            formData.append("show", values.show);

            await sendRequest(
              `${process.env.REACT_APP_API_URL}/api/products`,
              "POST",
              formData,
              { Authorization: "Bearer " + auth.token }
            );
            history.push("/backend/products");
          } catch (err) {}

          // console.log(values);
          setTimeout(() => {
            console.log(JSON.stringify(values, null, 2));
          }, 400);
        }}
      >
        {(formProps) => (
          <div className="product-form__background">
            <Card className="product-form__card">
              <h2 className="product-form__title--big">DATOS PRODUCTOS</h2>

              <Form className="product-form" encType="multipart/form-data">
                {error && (
                  <div className="auth-form__api-error-placeholder">
                    <div className="auth-form__api-error">{error}</div>
                  </div>
                )}
                <div className="product-form__content">
                  <div className="product-form__input-block product-form__input-block--grid">
                    <div className="product-form__input-block product-form__input-block--column">
                      <div className="product-form__error-placeholder product-form__error-placeholder--column">
                        <ErrorMessage
                          name="title"
                          component="div"
                          className="product-form__validation-error"
                        />
                      </div>
                      <label>Title</label>
                      <Field
                        type="text"
                        name="title"
                        placeholder="Titulo"
                        className="product-form__form-input product-form__form-input--inline"
                      />
                    </div>
                    <div className="product-form__input-block product-form__input-block--column">
                      <div className="product-form__error-placeholder product-form__error-placeholder--column">
                        <ErrorMessage
                          name="category"
                          component="div"
                          className="product-form__validation-error"
                        />
                      </div>
                      <label>Categoria</label>
                      <Select
                        className="product-form__form-input product-form__form-input--inline"
                        name="category"
                      >
                        <option value="">Category</option>
                        {loadedCategories &&
                          loadedCategories.map((items) => {
                            return (
                              <option value={items._id}>{items.title}</option>
                            );
                          })}
                      </Select>
                    </div>
                    <div className="product-form__input-block product-form__input-block--column">
                      <div className="product-form__error-placeholder product-form__error-placeholder--column">
                        <ErrorMessage
                          name="shortDescription"
                          component="div"
                          className="product-form__validation-error"
                        />
                      </div>
                      <label>Descripción Corta</label>
                      <Field
                        type="text"
                        name="shortDescription"
                        placeholder="Description"
                        className="product-form__form-input product-form__form-input--inline"
                      />
                    </div>
                    <div className="product-form__input-block product-form__input-block--column">
                      <div className="product-form__error-placeholder product-form__error-placeholder--column">
                        <ErrorMessage
                          name="summary"
                          component="div"
                          className="product-form__validation-error"
                        />
                      </div>
                      <label>Resumen</label>
                  
                      <Editor
                        editorState={editorStateSummary}
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor draft-component__border"
                        onEditorStateChange={setEditorStateSummary}
                        stripPastedStyles={true}
                        toolbar={toolbarProps}
                      />
                    </div>
                   
                    <div className="product-form__input-block product-form__input-block--column">
                      <div className="product-form__error-placeholder product-form__error-placeholder--column">
                        <ErrorMessage
                          name="moreInfo"
                          component="div"
                          className="product-form__validation-error"
                        />
                      </div>
                      <label>Más Información</label>
                      <Editor
                        editorState={editorStateMoreInfo}
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor draft-component__border"
                        onEditorStateChange={setEditorStateMoreInfo}
                        stripPastedStyles={true}
                        toolbar={toolbarProps}
                      />
                    </div>
                  </div>

                  <div className="product-form__input-block product-form__input-block--grid">
                    <div className="product-form__input-block product-form__input-block--column">
                      <div className="product-form__error-placeholder product-form__error-placeholder--column">
                        <ErrorMessage
                          name="imageUrl"
                          component="div"
                          className="product-form__validation-error"
                        />
                      </div>
                      <label>Imágenes de producto</label>
                      <input
                        type="file"
                        name="imageUrl"
                        multiple
                        onChange={(event) => {
                          formProps.setFieldValue(
                            "imageUrl",
                            event.target.files
                          );
                        }}
                      />
                    </div>
                    <div className="product-form__input-block product-form__input-block--column">
                      <div className="product-form__error-placeholder product-form__error-placeholder--column">
                        <ErrorMessage
                          name="price"
                          component="div"
                          className="product-form__validation-error"
                        />
                      </div>
                      <label>Precio</label>
                      <Field
                        type="number"
                        name="price"
                        placeholder="Price"
                        className="product-form__form-input product-form__form-input--inline"
                      />
                    </div>
                    <div className="product-form__input-block product-form__input-block--column">
                      <div className="product-form__error-placeholder product-form__error-placeholder--column">
                        <ErrorMessage
                          name="tickets"
                          component="div"
                          className="product-form__validation-error"
                        />
                      </div>
                      <label>Tickets</label>
                      <Field
                        type="number"
                        name="tickets"
                        placeholder="Tickets"
                        className="product-form__form-input product-form__form-input--inline"
                      />
                    </div>
                    <div className="product-form__input-block product-form__input-block--column">
                      <div className="product-form__error-placeholder product-form__error-placeholder--column">
                        <ErrorMessage
                          name="maxTickets"
                          component="div"
                          className="product-form__validation-error"
                        />
                      </div>
                      <label>Tickets Totales</label>
                      <Field
                        type="number"
                        name="maxTickets"
                        placeholder="Max Tickets"
                        className="product-form__form-input product-form__form-input--inline"
                      />
                    </div>
                    <div className="product-form__input-block product-form__input-block--column">
                      <div className="product-form__error-placeholder product-form__error-placeholder--column">
                        <ErrorMessage
                          name="expirationDate"
                          component="div"
                          className="product-form__validation-error"
                        />
                      </div>
                      <label>Fecha de Vencimiento</label>
                      <DateTimePicker 
                      value={formProps.values.expirationDate}
                      dateFormat="MMMM d, yyyy hh:mm:ss"
                      className="form-control"
                      timeFormat={true}
                      name="expirationDate"
                      onChange={date => formProps.setFieldValue('expirationDate', date)}
                      />
                    </div>
                    <div className="product-form__input-block product-form__input-block--column">
                      <div className="product-form__error-placeholder product-form__error-placeholder--column">
                        <ErrorMessage
                          name="status"
                          component="div"
                          className="product-form__validation-error"
                        />
                      </div>
                      <label>Status</label>
                      <Select
                        className="product-form__form-input product-form__form-input--inline"
                        name="status"
                      >
                        <option value="">Status</option>
                        <option value="active">Active</option>
                        <option value="closed">Closed</option>
                        <option value="winned">Winned</option>
                        <option value="expired">Expired</option>
                        <option value="inactive">Inactive</option>
                      </Select>
                    </div>
                   
                    <div className="product-form__input-block product-form__input-block--column">
                      <div className="product-form__error-placeholder product-form__error-placeholder--column">
                        <ErrorMessage
                          name="lotteryDate"
                          component="div"
                          className="product-form__validation-error"
                        />
                      </div>
                      <label>Fecha Sorteo</label>
                      <DateTimePicker 
                      value={formProps.values.lotteryDate}
                      dateFormat="MMMM d, yyyy hh:mm:ss"
                      className="form-control"
                      timeFormat={true}
                      name="lotteryDate"
                      onChange={date => formProps.setFieldValue('lotteryDate', date)}
                      />
                    </div>
                    <div className="product-form__input-block product-form__input-block--column">
                      <div className="product-form__error-placeholder product-form__error-placeholder--column">
                        <ErrorMessage
                          name="show"
                          component="div"
                          className="product-form__validation-error"
                        />
                      </div>
                      <label>Mostrar</label>
                      <Select
                        className="product-form__form-input product-form__form-input--inline"
                        name="show"
                      >
                        <option value="">Mostrar</option>
                        <option value="true">True</option>
                        <option value="false">False</option>
                      </Select>
                    </div>
                  </div>

                  <div className="help-form__footer">
                    <div className="help-form__button-block--grid">
                      <Button
                        className="main-button--squared-bigger main-button--80-width"
                        type="submit"
                        status="active"
                      >
                        Agregar
                      </Button>
                      <Button
                        to="/backend/products"
                        className="main-button--squared-bigger main-button--80-width product-form__back-button"
                      >
                        Atrás
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            </Card>
          </div>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default NewProduct;
