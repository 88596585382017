import React, { useContext } from "react";
import { Formik, Form, Field } from "formik";

import "./UsersList.css";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import Button from "../../../shared/components/FormElements/Button";
import GenericModal from "../../../shared/components/UIElements/GenericModal";
import { AuthContext } from "../../../shared/context/AuthContext";

const UsersList = (props) => {
    const auth = useContext(AuthContext);
    const { sendRequest, error, clearError } = useHttpClient();

    return (
        <div className="users-list__container">
            <table className="users-list__container-table">
                <GenericModal
                    message={error}
                    show={!!error}
                    error={true}
                    title={"Ocurrió un error"}
                    onClear={clearError}
                />
                <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Apellido</th>
                        <th>Email</th>
                        <th>Rol</th>
                        {props.user && props.user.type === "superadmin" && (
                            <th>Action</th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {props.userslist.map((item) => (
                        <tr key={item.id}>
                            <td>{item.firstName}</td>
                            <td>{item.lastName}</td>
                            <td>{item.email}</td>
                            <td>{item.type}</td>
                            {props.user && props.user.type === "superadmin" && (
                                <td>
                                    <Formik
                                        initialValues={{
                                            firstName: item.firstName,
                                            lastName: item.lastName,
                                            image: null,
                                            password: "",
                                            changePassword: "",
                                            telephone: item.telephone,
                                            type: item.type,
                                        }}
                                        onSubmit={async (values) => {
                                            const formData = new FormData();
                                            formData.append(
                                                "firstName",
                                                values.firstName
                                            );
                                            formData.append(
                                                "lastName",
                                                values.lastName
                                            );
                                            formData.append(
                                                "password",
                                                values.password
                                            );
                                            formData.append(
                                                "telephone",
                                                values.telephone
                                            );
                                            formData.append(
                                                "image",
                                                values.image
                                            );
                                            formData.append(
                                                "type",
                                                values.type
                                            );

                                            try {
                                                await sendRequest(
                                                    `${process.env.REACT_APP_API_URL}/api/users/${item.id}`,
                                                    "PATCH",
                                                    formData,
                                                    {
                                                        Authorization:
                                                            "Bearer " +
                                                            auth.token,
                                                    }
                                                );
                                                props.refresh(true);
                                            } catch (err) {}
                                        }}
                                    >
                                        <Form>
                                            <div>
                                                <label>
                                                    <Field
                                                        type="radio"
                                                        name="type"
                                                        value="admin"
                                                    />
                                                    Admin
                                                </label>
                                                <label>
                                                    <Field
                                                        type="radio"
                                                        name="type"
                                                        value="user"
                                                    />
                                                    User
                                                </label>
                                                <Button
                                                    status="active"
                                                    type="submit"
                                                >
                                                    Editar
                                                </Button>
                                            </div>
                                        </Form>
                                    </Formik>
                                </td>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default UsersList;
