import React, { useContext } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";

import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import Card from "../../../shared/components/UIElements/Card";
import BannerCategory from "../../../shared/components/UIElements/BannerCategory";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import { AuthContext } from "../../../shared/context/AuthContext";

import "./CategoriesForm.css";
import Button from "../../../shared/components/FormElements/Button";

const HelpSchema = Yup.object().shape({
  title: Yup.string().required("Requerido"),
  summary: Yup.string().required("Requerido"),
  image: Yup.mixed().required(),
});

const NewCategory = () => {
  const auth = useContext(AuthContext);
  const { sendRequest, error, clearError } = useHttpClient();

  const history = useHistory();

  return (
    <React.Fragment>
    <BannerCategory title="Crear Categoria"/>
    <Formik
      initialValues={{
        title: "",
        summary: "",
        image: "",
      }}
      validateOnMount
      validationSchema={HelpSchema}
      onSubmit={async (values) => {
        clearError();
        try {
          const formData = new FormData();
          formData.append("title", values.title);
          formData.append("summary", values.summary);
          formData.append("image", values.image);
          await sendRequest(
            `${process.env.REACT_APP_API_URL}/api/categories`,
            "POST",
            formData,
            { Authorization: "Bearer " + auth.token }
          );
          history.push("/backend/categories");
        } catch (err) {}

        console.log(values);
        setTimeout(() => {
          console.log(JSON.stringify(values, null, 2));
        }, 400);
      }}
    >
      {(formProps) => (
        <div className="category-form__background">
          <Card className="category-form__card">
            <h2 className="category-form__title--big">DATOS CATEGORIAS</h2>

            <Form className="category-form">
              {error && (
                <div className="auth-form__api-error-placeholder">
                  <div className="auth-form__api-error">{error}</div>
                </div>
              )}
              <div className="category-form__content">
                <div className="category-form__input-block category-form__input-block--grid">
                  <div className="category-form__input-block category-form__input-block--column">
                    <div className="category-form__error-placeholder category-form__error-placeholder--column">
                      <ErrorMessage
                        name="title"
                        component="div"
                        className="category-form__validation-error"
                      />
                    </div>
                    <Field
                      type="text"
                      name="title"
                      placeholder="Titulo"
                      className="category-form__form-input category-form__form-input--inline"
                    />
                  </div>
                </div>
                <div className="category-form__input-block category-form__input-block--grid">
                  <div className="category-form__input-block category-form__input-block--column">
                    <div className="category-form__error-placeholder category-form__error-placeholder--column">
                      <ErrorMessage
                        name="summary"
                        component="div"
                        className="category-form__validation-error"
                      />
                    </div>
                    <Field
                      type="text"
                      name="summary"
                      placeholder="Resumen"
                      className="category-form__form-input category-form__form-input--inline"
                    />
                  </div>
                </div>

                <div className="category-form__input-block category-form__input-block--grid">
                  <div className="category-form__input-block category-form__input-block--column">
                    <div className="category-form__error-placeholder category-form__error-placeholder--column">
                      <ErrorMessage
                        name="image"
                        component="div"
                        className="category-form__validation-error"
                      />
                    </div>

                    <input
                      type="file"
                      name="image"
                      onChange={(event) => {
                        formProps.setFieldValue("image", event.target.files[0]);
                      }}
                    />
                  </div>
                </div>
                <div className="help-form__footer">
                  <div className="help-form__button-block--grid"></div>
                  <Button
                    className="main-button--squared-bigger main-button--80-width"
                    type="submit"
                  >
                    Enviar
                  </Button>
                </div>
              </div>
            </Form>
          </Card>
        </div>
      )}
    </Formik>
    </React.Fragment>
  );
};

export default NewCategory;
