import React, { useEffect, useState, useContext } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useParams, useHistory } from "react-router-dom";
import * as Yup from "yup";

import BannerCategory from "../../../shared/components/UIElements/BannerCategory";
import GenericModal from "../../../shared/components/UIElements/GenericModal";
import Button from "../../../shared/components/FormElements/Button";
import Card from "../../../shared/components/UIElements/Card";
import LoadingSpinner from "../../../shared/components/UIElements/LoadingSpinner";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import { AuthContext } from "../../../shared/context/AuthContext";
import "./CategoriesForm.css";

const HelpSchema = Yup.object().shape({
    title: Yup.string().required("Requerido"),
    summary: Yup.string().required("Requerido"),
    image: Yup.mixed(),
});

const UpdateCategory = () => {
    const auth = useContext(AuthContext);
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const [loadedCategory, setLoadedCategory] = useState();
    const categoryId = useParams().categoryId;
    const history = useHistory();

    useEffect(() => {
        const fetchCategory = async () => {
            try {
                const responseData = await sendRequest(
                    `${process.env.REACT_APP_API_URL}/api/categories/${categoryId}`
                );
                setLoadedCategory(responseData.category);
            } catch (err) {}
        };
        fetchCategory();
    }, [sendRequest, categoryId]);

    if (isLoading) {
        return (
            <div className="center">
                <LoadingSpinner />
            </div>
        );
    }

    if (!loadedCategory && !error) {
        return (
            <div className="center">
                <Card>
                    <h2>Could not find category!</h2>
                </Card>
            </div>
        );
    }

    return (
        <React.Fragment>
            <GenericModal
                message={error}
                show={!!error}
                error={true}
                title={"Ocurrió un error"}
                onClear={clearError}
            />
            <BannerCategory
                title={`Editar Categoria: ${loadedCategory.title}`}
            />
            <Formik
                initialValues={{
                    title: loadedCategory.title,
                    summary: loadedCategory.summary,
                    image: loadedCategory.image,
                }}
                validationSchema={HelpSchema}
                onSubmit={async (values) => {
                    try {
                        const formData = new FormData();
                        formData.append("title", values.title);
                        formData.append("summary", values.summary);
                        formData.append("image", values.image);
                        await sendRequest(
                            `${process.env.REACT_APP_API_URL}/api/categories/${categoryId}`,
                            "PATCH",
                            formData,
                            { Authorization: "Bearer " + auth.token }
                        );
                        history.push("/backend/categories");
                    } catch (err) {}
                }}
            >
                {(formProps) => (
                    <div className="category-form__background">
                        <Card className="category-form__card">
                            <h2 className="category-form__title--big">
                                DATOS CATEGORIAS
                            </h2>
                            <Form className="category-form">
                                <div className="category-form__content">
                                    <div className="category-form__input-block category-form__input-block--grid">
                                        <div className="category-form__input-block category-form__input-block--column">
                                            <div className="category-form__error-placeholder category-form__error-placeholder--column">
                                                <ErrorMessage
                                                    name="title"
                                                    component="div"
                                                    className="category-form__validation-error"
                                                />
                                            </div>
                                            <Field
                                                type="text"
                                                name="title"
                                                placeholder="Titulo"
                                                className="category-form__form-input category-form__form-input--inline"
                                            />
                                        </div>
                                    </div>
                                    <div className="category-form__input-block category-form__input-block--grid">
                                        <div className="category-form__input-block category-form__input-block--column">
                                            <div className="category-form__error-placeholder category-form__error-placeholder--column">
                                                <ErrorMessage
                                                    name="summary"
                                                    component="div"
                                                    className="category-form__validation-error"
                                                />
                                            </div>
                                            <Field
                                                type="text"
                                                name="summary"
                                                placeholder="Resumen"
                                                className="category-form__form-input category-form__form-input--inline"
                                            />
                                        </div>
                                    </div>

                                    <div className="category-form__input-block category-form__input-block--grid">
                                        <div className="category-form__input-block category-form__input-block--column">
                                            <div className="category-form__error-placeholder category-form__error-placeholder--column">
                                                <ErrorMessage
                                                    name="image"
                                                    component="div"
                                                    className="category-form__validation-error"
                                                />
                                            </div>

                                            <input
                                                type="file"
                                                name="image"
                                                onChange={(event) => {
                                                    formProps.setFieldValue(
                                                        "image",
                                                        event.target.files[0]
                                                    );
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="help-form__footer">
                                        <div className="help-form__button-block--grid">
                                            <Button
                                                className="main-button--squared-bigger main-button--80-width"
                                                type="submit"
                                                status="active"
                                            >
                                                Editar
                                            </Button>
                                            <Button
                                                to="/backend/categories"
                                                className="main-button--squared-bigger main-button--80-width category-form__back-button"
                                            >
                                                Atrás
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </Card>
                    </div>
                )}
            </Formik>
        </React.Fragment>
    );
};
export default UpdateCategory;
