import React, { useEffect, useState } from "react";

import BannerCategory from "../../../shared/components/UIElements/BannerCategory";
import CategoryList from "../components/CategoryList";
import GenericModal from "../../../shared/components/UIElements/GenericModal";
import LoadingSpinner from "../../../shared/components/UIElements/LoadingSpinner";
import { useHttpClient } from "../../../shared/hooks/http-hook";

const UserCategories = () => {
    const [loadedCategories, setLoadedCategories] = useState();
    const { isLoading, error, sendRequest, clearError } = useHttpClient();

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const responseData = await sendRequest(
                    `${process.env.REACT_APP_API_URL}/api/categories`
                );
                setLoadedCategories(responseData.categories);
            } catch (err) {}
        };
        fetchCategories();
    }, [sendRequest]);

    const categoryDeletedHandler = (deletedCategoryId) => {
        setLoadedCategories((prevCategory) =>
            prevCategory.filter((category) => category.id !== deletedCategoryId)
        );
    };
    return (
        <React.Fragment>
            <GenericModal
                message={error}
                show={!!error}
                error={true}
                title={"Ocurrió un error"}
                onClear={clearError}
            />
            {isLoading && <LoadingSpinner />}
            <BannerCategory title="Administración de Categorias" />
            {!isLoading && loadedCategories && (
                <CategoryList
                    items={loadedCategories}
                    onDeleteCategory={categoryDeletedHandler}
                />
            )}
        </React.Fragment>
    );
};

export default UserCategories;
