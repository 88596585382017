import {React, useState, useEffect} from "react";

import { Modal } from "react-bootstrap";
import Button from "../FormElements/Button";
import { IoCloseSharp } from "react-icons/io5";
import "./GenericModal.css";

const GenericModal = (props) => {

    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(props.show);
      }, [props.show]);

    const handleClose = () => {
        !!props.onClear && props.onClear();
        setShow(false);    
    }
    
    return (
        <Modal
            className="generic-modal"
            onCancel={handleClose}
            show={show}
        >
            <div className="generic-modal-content">
                <div className="generic-modal__header">
                    <button
                        onClick={handleClose}
                        className="generic-modal__close"
                    >
                        <IoCloseSharp />
                    </button>
                    <div className="generic-modal__title">{props.title}</div>
                </div>
                <div className={`generic-modal__${props.error ? "error" : "regular"}-outline`}>
                    <div className={`generic-modal__${props.error ? "error" : "regular"}-message`}>
                        {props.message}
                    </div>
                </div>

                <div className="generic-modal__footer">
                    <Button
                        onClick={handleClose}
                        className="main-button--squared-bigger main-button--80-width"
                        status="active"
                    >
                        Aceptar
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default GenericModal;
