import React from "react";

import BannerLegal from "../../shared/components/UIElements/BannerLegal";
import TermsConditions from "../components/TermsConditions";

const LegalPage = () => {


    return (
        <div>
            <BannerLegal title='Legales' />
            <TermsConditions/>
        </div>

    );
};

export default LegalPage;