import React from "react";
import { useRef, useState, useEffect } from "react";

import { Link } from "react-router-dom";

import ProductCard from "./ProductCard.jsx";
import CategoryCard from "./CategoryCard.jsx";
import WinnerCard from "./WinnerCard.jsx";

import "./ScrollContainer.css";

// const DUMMY_SCROLL_CONTAINER_EXAMPLE = {
//     items: "Objeto de items que va a renderizar el carrousel",
//     title: "Título",
//     scroll: "Integer de píxeles que scrollean los botones",
//     verMasText: "Texto de botón Ver más",
//     verMasRef: "HRef/link del botón Ver más",
//     filters: "true o false si lleva o no filtros",
//     cardType: "tipo de card que debe renderizar el container",
// };

const ScrollContainer = (props) => {
    const [leftScrollShown, setLeftScrollShown] = useState(false);
    const [rightScrollShown, setRightScrollShown] = useState(null);
    const [filteredItems, setFilteredItems] = useState(props.items);
    const [activeFilter, setActiveFilter] = useState(null);

    const scrollRef = useRef();

    const maxScrollLeft = () => {
        return scrollRef.current.scrollWidth - scrollRef.current.clientWidth;
    };

    const cardTypes = {
        ProductCard: ProductCard,
        CategoryCard: CategoryCard,
        WinnerCard: WinnerCard,
    };

    const CardType = cardTypes[props.cardType];

    let categoryFilters = null;

    if (props.filters) {
        categoryFilters = Array.from(
            new Set(props.items.map((a) => a.category))
        ).filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);
    }

    const scrollHide = (currentScroll, maxScroll) => {
        if (maxScroll === 0) {
            setScrollShownHandler(false, false);
        } else if (currentScroll === 0) {
            setScrollShownHandler(false, true);
        } else if (Math.round(currentScroll) === Math.round(maxScroll)) {
            setScrollShownHandler(true, false);
        } else {
            setScrollShownHandler(true, true);
        }
    };

    useEffect(() => {
        scrollHide(scrollRef.current.scrollLeft, maxScrollLeft());
    });

    const scrollHandler = (scrollOffset) => {
        scrollRef.current.scrollLeft += scrollOffset;
        setTimeout(
            () => scrollHide(scrollRef.current.scrollLeft, maxScrollLeft()),
            400
        );
    };

    const setScrollShownHandler = (left, right) => {
        setLeftScrollShown(left);
        setRightScrollShown(right);
    };

    const filterHandler = (category) => {
        if (category) {
            let filteredItems = props.items.filter(
                (prop) => prop.category.id === category.id
            );
            setFilteredItems(filteredItems);
            setActiveFilter(filteredItems[0].category.id);
        } else {
            setFilteredItems(props.items);
            setActiveFilter(null);
        }
        setTimeout(
            () => scrollHide(scrollRef.current.scrollLeft, maxScrollLeft()),
            400
        );
    };

    return (
        <div className="scroll-container">
            <div className="scroll-container__top">
                {props.title && (
                    <div className="scroll-container__header">
                        <h3 className="scroll-container__title">
                            {props.title}
                        </h3>
                    </div>
                )}
                {!props.filters && props.verMasText && (
                    <Link to={props.verMasRef}>
                        <button className="scroll-container__items-more-button">
                            {props.verMasText}
                        </button>
                    </Link>
                )}
            </div>
            {props.filters && (
                <div className="scroll-container__filters">
                    <div>
                        {categoryFilters &&
                            categoryFilters.map((category) => {
                                return (
                                    <button
                                        key={category.id}
                                        className={
                                            "scroll-container__filter-button" +
                                            (activeFilter === category.id
                                                ? " scroll-container__filter-button--active"
                                                : "")
                                        }
                                        onClick={() => filterHandler(category)}
                                    >
                                        {category.title}
                                    </button>
                                );
                            })}
                        <button
                            className={
                                "scroll-container__filter-button" +
                                (activeFilter === null
                                    ? " scroll-container__filter-button--active"
                                    : "")
                            }
                            onClick={() => filterHandler(null)}
                        >
                            Ver todos
                        </button>
                    </div>
                    <button className="scroll-container__items-more-button">
                        {props.verMasText}
                    </button>
                </div>
            )}

            <div className="scroll-container__content">
                <div className="scroll-container__buttons">
                    <Fade show={leftScrollShown} direction="Left">
                        <div
                            className="scroll-container__button-left"
                            onClick={() => scrollHandler(-props.scroll)}
                        ></div>
                    </Fade>
                    <Fade show={rightScrollShown} direction="Right">
                        <div
                            className="scroll-container__button-right"
                            onClick={() => scrollHandler(+props.scroll)}
                        ></div>
                    </Fade>
                </div>
                <div
                    className="scroll-container__list scroll-container__list--scroll-behavior"
                    ref={scrollRef}
                >
                    {filteredItems.map((item) => (
                        <CardType key={item.id} {...item} />
                    ))}
                </div>
            </div>
        </div>
    );
};

const Fade = ({ show, direction, children }) => {
    const [render, setRender] = useState(show);

    useEffect(() => {
        if (show) setRender(true);
    }, [show]);

    const onAnimationEnd = () => {
        if (!show) setRender(false);
    };

    return (
        render && (
            <div
                style={{
                    animation: `${
                        show ? `fadeIn${direction}` : `fadeOut${direction}`
                    } 0.2s`,
                    zIndex: 5,
                }}
                onAnimationEnd={onAnimationEnd}
            >
                {children}
            </div>
        )
    );
};

export default ScrollContainer;
