import React from "react";

import PillboxCounter from "../FormElements/PillboxCounter";

import "./CartItemNav.css";

const CartItemNav = ({ product }) => {
    return (
        <div className="cart-nav-item">
            <img
                className="cart-nav-item__image"
                alt={product.name}
                src={`${process.env.REACT_APP_API_URL}/${product.imageUrl[0]}`}
            />
            <div className="cart-nav-item__content">
                <div className="cart-nav-item__content-top">
                    <h4 className="cart-nav-item__title">{product.title}</h4>
                    <div className="cart-nav-item__pricing">
                        ${product.price}
                    </div>
                </div>
                <div className="cart-nav-item__content-middle">
                    <div className="cart-nav-item__bullet-points">
                        <p>- Válido hasta el {product.expiration}</p>
                    </div>
                </div>
                <div className="cart-nav-item__content-bottom">
                    <PillboxCounter {...product} source="cart" size="small" />

                    <div className="cart-nav-item__subtotal">
                        Subtotal: ${product.quantity * product.price}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CartItemNav;
