import React from "react";
import { DateTime } from "luxon";

import UserBox from "./UserBox";
import WinnerSection from "./WinnerSection";
import ProductParticipationList from "./ProductParticipationList";
import UserCounterSection from "./UserCounterSection";
import "./ProductPurchased.css";

const ProductPurchased = (props) => {
  function isLotteryFinished() {
    let start = DateTime.fromISO(DateTime.now());
    var end = DateTime.fromISO(props.lotteryDate);

    const diff = end.diff(start, [
      "months",
      "days",
      "hours",
      "minutes",
      "seconds",
    ]);

    const difference = diff.seconds;

    //const difference = props.lotteryDate - +new DateTime.now();
    let showSpinner = true;
    if (difference > 0) {
      showSpinner = false;
    } else {
      /// ACA SE DEBERIA REFRESCAR LA INFO DEL PRODUCTO
      showSpinner = true;
    }

    return showSpinner;
  }

  return (
    <div>
      {(props.productStatus === "active" ||
        props.productStatus === "closed" || props.productStatus === "winned") && (
          <React.Fragment>
            {( props.productStatus === "closed" || props.productStatus === "winned" )  && (
              <UserCounterSection
                lotterydate={props.lotteryDate}
                winner={props.winner}
                userName={props.userName}
                title={props.title}
                isWinner={props.isWinner}
                showSpinner={isLotteryFinished()}
              />
            )}
            <UserBox
              state={props.productStatus}
              userName={props.userName}
              tickets={props.tickets}
              probability={props.probability}
            />
            <ProductParticipationList participants={props.participants} />
          </React.Fragment>
        )}
      {props.productStatus === "raffled" && (
        <React.Fragment>
          <WinnerSection
            winner={props.winner}
            userName={props.userName}
            title={props.title}
            isWinner={props.isWinner}
          />
          <ProductParticipationList participants={props.participants} />
        </React.Fragment>
      )}
    </div>
  );
};

export default ProductPurchased;
