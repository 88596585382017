import React from "react";

import './HelpPageText.css'

const HelpPageText = () =>{

    return(
    <div className="help__content">
        <h2 className="help__title--big">¿Tenés otras consultas?</h2>
        <h3 className="help__title">Contactanos</h3>
        <p className="help__subcontent--bullet" ></p>
        <p className="help__subcontent" >Te responderemos a la mayor brevedad posible, pero recuerda que nos puedes escribir a nuestro <span className="help__sub-title">Whatsapp</span></p>
        <p className="help__sub-title" >Email</p>
        <p className="help__subcontent" >info@bigtime.com.ar</p>
        <p className="help__sub-title" >Ventas Corporativas</p>
        <p className="help__subcontent" >comercial@bigtime.com.ar</p>
        <p className="help__sub-title" >Ventas Telefónicas</p>
        <p className="help__subcontent" >0810 111 2424</p>
        <p className="help__subcontent" >0810 111 2425</p>
        <p className="help__sub-title" >Lunes a Viernes de 10 a 18hs.</p> 
    </div>



    );

};

export default HelpPageText;