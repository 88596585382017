import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { convertFromRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";

import "./ProductMoreInfo.css";

const ProductMoreInfo = (props) => {
  const [key, setKey] = useState("summary");

  const [editorStateSummary, setEditorStateSummary] =
    useState(() => EditorState.createWithContent(convertFromRaw(JSON.parse(props.summary))));
  const [editorStateMoreInfo, setEditorStateMoreInfo] = useState(
    () => EditorState.createWithContent(convertFromRaw(JSON.parse(props.moreInfo)))
  );


  return (
    <div className="product-moreinfo__container">
      <Tabs
        fill
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
      >
        <Tab eventKey="summary" title="Resumen">
          <Editor
            readOnly={true}
            toolbarHidden
            editorState={editorStateSummary}
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            onEditorStateChange={setEditorStateSummary}
          />
        </Tab>
        <Tab eventKey="moreinfo" title="¿Qué incluye?">
        <Editor
            readOnly={true}
            toolbarHidden
            editorState={editorStateMoreInfo}
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            onEditorStateChange={setEditorStateMoreInfo}
          />
        </Tab>
      </Tabs>
      <hr />
    </div>
  );
};

export default ProductMoreInfo;
