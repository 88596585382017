import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import ScrollContainer from "../../shared/components/UIElements/ScrollContainer";
import BannerCategory from "../../shared/components/UIElements/BannerCategory";
import Filter from "../../shared/components/UIElements/Filter";
import GenericModal from "../../shared/components/UIElements/GenericModal";
import PurchasesContainer from "../components/PurchasesContainer";
import { AuthContext } from "../../shared/context/AuthContext";

import "./Purchases.css";

import { ProductsContext } from "../../shared/context/ProductsContext";
import { useHttpClient } from "../../shared/hooks/http-hook";

const UserBuysPage = () => {
    const { products } = useContext(ProductsContext);
    const { sendRequest, isLoading, error, clearError } = useHttpClient();
    const auth = useContext(AuthContext);

    const [purchases, setPurchases] = useState(null);
    const [productsPurchased, setProductsPurchased] = useState(null);
    const [stateValue, setStateValue] = useState("Todos");
    const [categoryValue, setCategoryValue] = useState("Todas");
    const [priceValue, setPriceValue] = useState("Desde $0");
    const [progressValue, setProgressValue] = useState("Desde 0%");

    const userId = useParams().userId;

    useEffect(() => {
        const ordersFetch = async () => {
            try {
                const responseData = await sendRequest(
                    `${process.env.REACT_APP_API_URL}/api/orders/user/${userId}`,
                    "GET",
                    null,
                    {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + auth.token,
                    }
                );

                setPurchases(responseData.filteredPurchases);
                setProductsPurchased(responseData.filteredProducts);
            } catch (err) {}
        };
        ordersFetch();
    }, [sendRequest, userId, auth.token]);

    // // Toma la información de las órdenes y agrupa los productos cuando hay varias compras

    return (
        <React.Fragment>
            <GenericModal
                message={error}
                show={!!error}
                error={true}
                title={"Ocurrió un error"}
                onClear={clearError}
            />
            {!isLoading && purchases && (
                <div>
                    <BannerCategory title="Mis Compras" />
                    <div className="user-purchases-page__top-info">
                        <div className="text-left">
                            <p className="user-purchases-page__title">
                                Casi son tuyos!
                            </p>
                        </div>
                        <div className="text-right">
                            <p className="user-purchases-page__total">
                                Participaste en {purchases.length}{" "}
                                {purchases.length > 0 ? "sorteos" : "sorteo"}
                            </p>
                        </div>
                    </div>
                </div>
            )}
            {!isLoading && purchases && productsPurchased && (
                <div className="user-purchases-page__info--container">
                    <Filter
                        state={true}
                        categories={true}
                        prices={false}
                        progress={false}
                        priceId={priceValue}
                        stateId={stateValue}
                        progressId={progressValue}
                        onState={setStateValue}
                        onPrice={setPriceValue}
                        onProgress={setProgressValue}
                        categoryId={categoryValue}
                        onStateCategory={setCategoryValue}
                    />
                    <PurchasesContainer
                        purchases={purchases}
                        stateId={stateValue}
                        categoryId={categoryValue}
                        products={productsPurchased}
                    />
                </div>
            )}
            {!isLoading && products && (
                <ScrollContainer
                    items={products}
                    title="Nuevos Lanzamientos."
                    scroll="700"
                    verMasText="Ver más"
                    verMasRef="/"
                    cardType="ProductCard"
                />
            )}
        </React.Fragment>
    );
};

export default UserBuysPage;
