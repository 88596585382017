import React, { useState, useEffect} from "react";
import { ButtonGroup, ToggleButton } from "react-bootstrap";

import { useHttpClient } from "../../hooks/http-hook";
import { BiFilterAlt } from "react-icons/bi";
import "./Filter.css";

const Filter = (props) => {

  const { sendRequest } = useHttpClient();
  const [loadedCategories, setLoadedCategories] = useState();

  const onHandleChange = (stateValue) => {
    props.onState(stateValue);
  }

  const onHandleCategoryChange = (value) => {
    props.onStateCategory(value);
  }

  const onHandlePriceChange = (value) => {
    props.onPrice(value);
  }

  const onHandleProgressChange = (value) => {
    props.onProgress(value);
  }
  

  const erasefilter = () => {
    if (props.state === false){
      props.onState('Activo');
    }else{
      props.onState('Todos');
    }
    props.onStateCategory('Todas');
    props.onPrice('Desde $0');
    props.onProgress('Desde 0%');
  }

  const statesId = [
    { name: 'Activo', value: 'Activo' },
    { name: 'Rifado', value: 'Rifado' },
    { name: 'Finalizado', value: 'Finalizado' },
    { name: 'Todos', value: 'Todos' },
  ];

  useEffect(() => {
    const fetchCategories = async () => {
        try {
            const responseData = await sendRequest(
                `${process.env.REACT_APP_API_URL}/api/categories`
            );
            setLoadedCategories(responseData.categories);
        } catch (err) {}
    };
    fetchCategories();
}, [sendRequest]);

  /*const categoriesId = [
    { name: 'Todas', value: 'Todas' },
    { name: 'Productos', value: 'Productos' },
    { name: 'Viajes', value: 'Viajes' },
    { name: 'Entretenimiento', value: 'Entretenimiento' },
    { name: 'Gastronomía', value: 'Gastronomía' },
    { name: 'Eventos', value: 'Eventos' },
    { name: 'Imperdibles', value: 'Imperdibles' },
  ]*/

  const pricesId = [
    { name: 'Desde $0', value: 'Desde $0' },
    { name: 'Desde $100', value: 'Desde $100' },
    { name: 'Desde $200', value: 'Desde $200' },
    { name: 'Desde $500', value: 'Desde $500' },
    { name: 'Desde $1.000', value: 'Desde $1.000' },
    { name: 'Desde $3.000', value: 'Desde $3.000' },
    { name: 'Desde $5.000', value: 'Desde $5.000' },
  ]

  const progressId = [
    { name: 'Desde 0%', value: 'Desde 0%' },
    { name: 'Desde 25%', value: 'Desde 25%' },
    { name: 'Desde 50%', value: 'Desde 50%' },
    { name: 'Desde 75%', value: 'Desde 75%' },
  ]

  return (
    <React.Fragment>
    <div className="filter__container">
     <p className="filter__main-title"><BiFilterAlt size={30} className="filter__main-icon"/> Filtrar</p>
    

    { props.state === true ? (<div>
      <h4 className="filter__main-subtitle">Estado</h4>
     
      <ButtonGroup toggle className="filter__state-container">
        {statesId.map((state, idx) => (
          <ToggleButton className="filter__state--button"
            key={idx}
            type="checkbox"
            variant="secondary"
            name="state"
            value={state.value}
            checked={props.stateId === state.value}
            onChange={(e) => { onHandleChange(e.currentTarget.value);
             }}
          >
            {state.name}
          </ToggleButton>
        ))}
      </ButtonGroup>


    </div>) : '' }

    { props.categories === true ? (<div> 
      <h4 className="filter__main-subtitle">Categories</h4>
      <ButtonGroup  className="filter__categories-container">
        {loadedCategories &&
                                loadedCategories.map((category, idc) => (
          <ToggleButton className="filter__categories--button"
            key={idc}
            type="checkbox"
            variant="secondary"
            name="category"
            value={category.title}
            checked={props.categoryId === category.title}
            onChange={(f) => { onHandleCategoryChange(f.currentTarget.value);
             }}
          >
            {category.title}
          </ToggleButton>
        ))}
      </ButtonGroup>
    </div>) : '' }

    { props.prices === true ? (<div> 
      <h4 className="filter__main-subtitle">Ofertas</h4>
      <ButtonGroup  className="filter__categories-container">
        {pricesId.map((price, idp) => (
          <ToggleButton className="filter__categories--button"
            key={idp}
            type="checkbox"
            variant="secondary"
            name="prices"
            value={price.value}
            checked={props.priceId === price.value}
            onChange={(g) => { onHandlePriceChange(g.currentTarget.value);
             }}
          >
            {price.name}
          </ToggleButton>
        ))}
      </ButtonGroup>
    </div>) : '' }

    { props.progress === true ? (<div> 
      <h4 className="filter__main-subtitle">Progreso</h4>
      <ButtonGroup  className="filter__categories-container">
        {progressId.map((progress, idpr) => (
          <ToggleButton className="filter__categories--button"
            key={idpr}
            type="checkbox"
            variant="secondary"
            name="progress"
            value={progress.value}
            checked={props.progressId === progress.value}
            onChange={(p) => { onHandleProgressChange(p.currentTarget.value);
             }}
          >
            {progress.name}
          </ToggleButton>
        ))}
      </ButtonGroup>
    </div>) : '' }

    <button className="filter__erase-filter" onClick={erasefilter}>Limpiar filtro</button>
    </div>
    </React.Fragment>
  );
};


export default Filter;
