import React, { useState, useContext } from "react";

import { Link } from "react-router-dom";
import Card from "../../../shared/components/UIElements/Card";
import GenericModal from "../../../shared/components/UIElements/GenericModal";
import Button from "../../../shared/components/FormElements/Button";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import { Modal } from "react-bootstrap";
import { AuthContext } from "../../../shared/context/AuthContext";
import "./ProductList.css";

let iditem;

const ProductList = (props) => {
    const { error, sendRequest, clearError } = useHttpClient();
    const auth = useContext(AuthContext);
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const productPorcentaje = (tickets, maxTickets) => {
        let porcentaje = Math.floor((tickets / maxTickets) * 100) + "%";
        return porcentaje;
    };

    const showDeleteWarningHandler = () => {
        setShowConfirmModal(true);
    };

    const cancelDeleteHandler = () => {
        setShowConfirmModal(false);
    };

    const confirmDeleteHandler = async () => {
        setShowConfirmModal(false);

        try {
            await sendRequest(
                `${process.env.REACT_APP_API_URL}/api/products/${iditem}`,
                "DELETE",
                null,
                { Authorization: "Bearer " + auth.token }
            );
            props.onDeleteProduct(iditem);
        } catch (err) {}
    };

    if (props.items.length === 0) {
        return (
            <div className="mainslider-list center">
                <Card>
                    <h2>No hay productos.</h2>
                    <Button
                        to="/backend/products/new"
                        className="mainslider-form__back-button "
                    >
                        Crear
                    </Button>
                </Card>
            </div>
        );
    }

    return (
        <React.Fragment>
            <GenericModal
                message={error}
                show={!!error}
                error={true}
                title={"Ocurrió un error"}
                onClear={clearError}
            />
            <Modal
                show={showConfirmModal}
                onCancel={cancelDeleteHandler}
                header="Are you sure?"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Esta seguro?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Quiere borrar este producto?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        inverse
                        status="active"
                        onClick={cancelDeleteHandler}
                    >
                        CANCELAR
                    </Button>
                    <Button danger onClick={confirmDeleteHandler}>
                        BORRAR
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal></Modal>
            <Link to="/backend/products/new">
                <p className="main-slider-list__button">Crear nuevo Producto</p>
            </Link>
            <div className="users-list__container">
                <table className="users-list__container-table">
                    <thead>
                        <tr>
                            <th>Fecha</th>
                            <th>Titulo</th>
                            <th>Porcentaje</th>
                            <th>Fecha Vencimiento</th>
                            <th>Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.items.map((item) => (
                            <tr key={item.id}>
                                <td>{item.createdAt}</td>
                                <td>{item.title}</td>
                                <td>
                                    {productPorcentaje(
                                        item.tickets,
                                        item.maxTickets
                                    )}
                                </td>
                                <td>{item.expirationDate}</td>
                                <td>{item.status}</td>
                                <td>
                                    <Button
                                        status="active"
                                        to={`/backend/products/${item.id}`}
                                    >
                                        EDITAR
                                    </Button>
                                    <Button
                                        danger
                                        onClick={() => {
                                            iditem = item.id;
                                            showDeleteWarningHandler();
                                        }}
                                    >
                                        BORRAR
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    );
};

export default ProductList;
