import { React, useContext } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { IoCloseSharp } from "react-icons/io5";
import { Modal } from "react-bootstrap";
import * as Yup from "yup";
import "./AuthModal.css";

import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/AuthContext";
import Button from "../../shared/components/FormElements/Button";

const LoginSchema = Yup.object().shape({
    email: Yup.string().email("Correo inválido").required("Requerido"),
    password: Yup.string()
        .min(6, "Al menos 6 caracteres")
        .max(24, "Demasiado larga")
        .required("Requerida"),
});

const SignupSchema = Yup.object().shape({
    firstName: Yup.string().max(24, "Demasiado largo").required("Requerido"),
    lastName: Yup.string().max(24, "Demasiado largo").required("Requerido"),
    email: Yup.string().email("Correo inválido").required("Requerido"),
    password: Yup.string()
        .min(6, "Al menos 6 caracteres")
        .max(24, "Demasiado larga")
        .required("Requerida"),
    confirmPassword: Yup.string().oneOf(
        [Yup.ref("password"), null],
        "Las contraseñas deben coincidir"
    ),
    telephone: Yup.number().min(8, "No es válido").required("Requerido"),
    termsConditions: Yup.boolean().required(),
});

const AuthModal = (props) => {
    return (
        <Modal className="auth-modal" show={props.show} onHide={props.onHide}>
            <div className="auth-modal__header">
                <button onClick={props.redirect || props.onHide} className="auth-modal__close">
                    <IoCloseSharp />
                </button>
                <div className="auth-modal__title">{props.title}</div>
            </div>
            {props.isModalLogin && (
                <Login
                    hide={props.onHide}
                    setIsModalLogin={props.setIsModalLogin}
                />
            )}
            {!props.isModalLogin && (
                <Signup
                    hide={props.onHide}
                    setIsModalLogin={props.setIsModalLogin}
                />
            )}
        </Modal>
    );
};

const Login = (props) => {
    const auth = useContext(AuthContext);
    const { sendRequest, error, clearError } = useHttpClient();


    const switchHandler = () => {
        props.setIsModalLogin(false);
    };

    const hideHandler = () => {
        props.hide();
    };
    
    return (
        <Formik
            initialValues={{ email: "", password: "" }}
            validationSchema={LoginSchema}

            onSubmit={async (values, { resetForm }) => {
                clearError();
                try {
                    const responseData = await sendRequest(
                        `${process.env.REACT_APP_API_URL}/api/users/login`,
                        "POST",
                        JSON.stringify({
                            email: values.email,
                            password: values.password,
                        }),
                        {
                            "Content-Type": "application/json",
                        }
                    );
                    auth.login(responseData);
                    hideHandler();

                } catch (err) {
                    resetForm();
                }
            }}


        >
            {({ isValid, dirty, isSubmitting }) => (
                <Form className="auth-form">
                    {error && (
                        <div className="auth-form__api-error-placeholder">
                            <div className="auth-form__api-error">{error}</div>
                        </div>
                    )}
                    <div className="auth-form__content">
                        <div className="auth-form__input-block">
                            <div className="auth-form__error-placeholder">
                                <ErrorMessage
                                    name="email"
                                    component="div"
                                    className="auth-form__validation-error"
                                />
                            </div>
                            <Field
                                type="email"
                                name="email"
                                placeholder="Email"
                                className="auth-form__form-input"
                            />
                        </div>

                        <div className="auth-form__input-block">
                            <div className="auth-form__error-placeholder">
                                <ErrorMessage
                                    name="password"
                                    component="div"
                                    className="auth-form__validation-error"
                                />
                            </div>
                            <Field
                                type="password"
                                name="password"
                                placeholder="Contraseña"
                                className="auth-form__form-input"
                            />
                        </div>
                    </div>

                    <button className="auth-form__link">
                        ¿Olvidaste tu contraseña?
                    </button>

                    <div className="auth-form__footer">
                        <Button
                            className="main-button--squared-bigger main-button--80-width"
                            status={dirty && isValid ? "active" : "inactive"}
                            disabled={isSubmitting}
                            type="submit"
                        >
                            Ingresar
                        </Button>
                        <div>
                            ¿No estás registrado?{" "}
                            <button
                                id="signup-switch"
                                className="auth-form__link"
                                type="button"
                                onClick={switchHandler}
                            >
                                Registrate
                            </button>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

const Signup = (props) => {
    const auth = useContext(AuthContext);
    const { sendRequest, error, clearError } = useHttpClient();

    const switchHandler = () => {
        props.setIsModalLogin(true);
    };

    const hideHandler = () => {
        props.hide();
    };

    return (
        <Formik
            initialValues={{
                firstName: "",
                lastName: "",
                email: "",
                password: "",
                confirmPassword: "",
                telephone: "",
                termsConditions: "",
            }}
            validationSchema={SignupSchema}
            onSubmit={async (values, { setFieldValue, resetForm }) => {
                clearError();
                try {
                    const responseData = await sendRequest(
                        `${process.env.REACT_APP_API_URL}/api/users/signup`,
                        "POST",
                        JSON.stringify({
                            firstName: values.firstName,
                            lastName: values.lastName,
                            email: values.email,
                            password: values.password,
                            telephone: values.telephone,
                            termsConditions: values.termsConditions,
                        }),
                        {
                            "Content-Type": "application/json",
                        }
                    );
                    const responseDataMail = await sendRequest(
                        `${process.env.REACT_APP_API_URL}/api/mails/sendmail`,
                        "POST",
                        JSON.stringify({
                            firstName: values.firstName,
                            email: values.email,
                            message: "Ya estas listo para comenzar a participar de impresionantes sorteos y (si tenes suerte) disfrutar de grandes premios. En WinBig vas a encontrar... todo lo que imagines y mas!!!",
                        }),
                        {
                            "Content-Type": "application/json",
                        }
                    );
                    
                    if (responseDataMail.approved === 'sent'){

                        auth.login(responseData);

                        hideHandler();
                    }
                    
                } catch (err) {}
            }}
        >
            {({ isValid, dirty }) => (
                <div>
                    <Form className="auth-form">
                        {error && (
                            <div className="auth-form__api-error-placeholder">
                                <div className="auth-form__api-error">
                                    {error}
                                </div>
                            </div>
                        )}
                        <div className="auth-form__content">
                            <div className="auth-form__input-block auth-form__input-block--grid">
                                <div className="auth-form__input-block auth-form__input-block--column">
                                    <div className="auth-form__error-placeholder auth-form__error-placeholder--column">
                                        <ErrorMessage
                                            name="firstName"
                                            component="div"
                                            className="auth-form__validation-error"
                                        />
                                    </div>
                                    <Field
                                        type="firstName"
                                        name="firstName"
                                        placeholder="Nombre"
                                        className="auth-form__form-input auth-form__form-input--inline"
                                    />
                                </div>
                                <div className="auth-form__input-block auth-form__input-block--column">
                                    <div className="auth-form__error-placeholder auth-form__error-placeholder--column">
                                        <ErrorMessage
                                            name="lastName"
                                            component="div"
                                            className="auth-form__validation-error"
                                        />
                                    </div>
                                    <Field
                                        type="lastName"
                                        name="lastName"
                                        placeholder="Apellido"
                                        className="auth-form__form-input auth-form__form-input--inline"
                                    />
                                </div>
                            </div>

                            <div className="auth-form__input-block">
                                <div className="auth-form__error-placeholder">
                                    <ErrorMessage
                                        name="email"
                                        component="div"
                                        className="auth-form__validation-error"
                                    />
                                </div>
                                <Field
                                    type="email"
                                    name="email"
                                    placeholder="Email"
                                    className="auth-form__form-input"
                                />
                            </div>

                            <div className="auth-form__input-block">
                                <div className="auth-form__error-placeholder">
                                    <ErrorMessage
                                        name="password"
                                        component="div"
                                        className="auth-form__validation-error"
                                    />
                                </div>
                                <Field
                                    type="password"
                                    name="password"
                                    placeholder="Contraseña"
                                    className="auth-form__form-input"
                                />
                            </div>
                            <div className="auth-form__input-block">
                                <div className="auth-form__error-placeholder">
                                    <ErrorMessage
                                        name="confirmPassword"
                                        component="div"
                                        className="auth-form__validation-error"
                                    />
                                </div>
                                <Field
                                    type="password"
                                    name="confirmPassword"
                                    placeholder="Confirmar Contraseña"
                                    className="auth-form__form-input"
                                />
                            </div>

                            <div className="auth-form__input-block">
                                <div className="auth-form__error-placeholder">
                                    <h4 className="auth-form__input-label">
                                        Teléfono
                                    </h4>
                                    <ErrorMessage
                                        name="telephone"
                                        component="div"
                                        className="auth-form__validation-error"
                                    />
                                </div>
                                <Field
                                    type="telephone"
                                    name="telephone"
                                    placeholder="(+54)"
                                    className="auth-form__form-input"
                                />
                            </div>
                            <div className="auth-form__input-block mt-3">
                                <Field type="checkbox" name="termsConditions" />{" "}
                                He leído y acepto todos los{" "}
                                <button className="auth-form__link">
                                    términos y condiciones
                                </button>
                            </div>
                        </div>

                        <div className="auth-form__footer">
                            <Button
                                className="main-button--squared-bigger main-button--80-width"
                                status={
                                    dirty && isValid ? "active" : "inactive"
                                }
                                type="submit"
                            >
                                Registrate
                            </Button>
                            <div>
                                ¿Ya tenés una cuenta en BigTime?{" "}
                                <button
                                    id="signup-switch"
                                    className="auth-form__link"
                                    type="button"
                                    onClick={switchHandler}
                                >
                                    Iniciá sesión
                                </button>
                            </div>
                        </div>
                    </Form>
                </div>
            )}
        </Formik>
    );
};

export default AuthModal;
