import React, { useContext } from "react";
import { Route, useHistory } from "react-router-dom";
import BlankPage from "../../shared/pages/BlankPage";
import GenericModal from "../components/UIElements/GenericModal";

import { AuthContext } from "../../shared/context/AuthContext";

const AdminRoute = ({ component: Component, ...restOfProps }) => {
    const auth = useContext(AuthContext);

    const authAdmin =
        auth.loggedUserData.type === "admin" ||
        auth.loggedUserData.type === "superadmin";

    const history = useHistory();

    const redirectHandler = () => {
        let url = `/`;
        history.push(url);
    };

    return (
        <Route
            {...restOfProps}
            render={(props) => {
                if (!auth.isLoggedIn) return <BlankPage modal={true} />;
                if (!authAdmin)
                    return (
                        <GenericModal
                            message={
                                "Necesitas permisos de administrador para acceder a esta página."
                            }
                            show={true}
                            error={true}
                            title={"Ocurrió un error"}
                            onClear={redirectHandler}
                        />
                    );
                return <Component {...props} />;
            }}
        />
    );
};

export default AdminRoute;
