import React from "react";
import { DateTime } from "luxon";

import "./ProductInfo.css";

import { BiCalendar, BiHappyAlt } from "react-icons/bi";
import PillboxCounter from "../../../src/shared/components/FormElements/PillboxCounter";

// const DUMMY_PRODUCT_EXAMPLE = {
//     id: "1",
//     title: "Titulo de producto",
//     category: "Categoría de producto",
//     description: "Descripción de producto",
//     imageUrl: "Espera una URL, a cambiar o definir luego",
//     price: "precio",
//     tickets: "número de tickets en Integer",
//     maxTickets: "número máximo de tickets en Integer",
//     status: "active o inactive dependiendo de si está completado o no",
// };

const ProductInfo = (props) => {

    const parsedExpiration = DateTime.fromISO(props.expirationDate).toLocaleString();

    return (
        <div className="product-info">
            <p
                className={`product-info__candidates product-info__candidates--${props.status}`}
            >
                {props.tickets}/{props.maxTickets} Candidatos
                <span className="product-card__percentage">
                    {" "}
                    (
                    {Math.floor(
                        (props.tickets / props.maxTickets) * 100
                    )}
                    %)
                </span>{" "}
                <BiHappyAlt className="product-info__candidates--icon" />
            </p>
            <hr className="product-info__line" />
            <h3 className="product-info__title">{props.title}</h3>
            <p>{props.shortDescription}</p>
            <p>
                <BiCalendar size={50} className="product-info__icon-calendar" />
                <strong> Valido hasta el {parsedExpiration}</strong>
            </p>
            <div className="product-info__buy--container">
                <div>
                    <p className="product-info__buy--pricing">${props.price}</p>
                </div>
                <PillboxCounter {...props} source="productpage"/>
            </div>
        </div>
    );
};

export default ProductInfo;
