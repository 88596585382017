import React from "react";

import { Link } from "react-router-dom";

import "./OpportunityCard.css";

const OpportunityCard = (props) => {
    return (
        <Link to={{
            pathname: `${props.href}`,
            state: props.state,
            }} className={`opportunity-card opportunity-card--${props.color}`}>
                <div className="opportunity-card__content">{props.title}</div>
        </Link>
    );
};

export default OpportunityCard;
