import React from "react";

import ProductCard from '../../shared/components/UIElements/ProductCard';
import "./ProductSectionContainer.css";

const ProductSectionContainer = (props) => {
  return (
    <div className="product-section-container__container">
      {props.products.map((item) => (
        <ProductCard key={item.id} {...item} />
      ))}
    </div>
  );
};

export default ProductSectionContainer;
