import React from "react";

import "./ProductParticipationList.css";


const ProductParticipationList = (props) => {

  return (
    <div className="product-participation-list__container">
         <h3>Ellos/Ellas también participan por el premio</h3>
          <div className="product-participation-list__user-container">

         
          {props.participants.map( participant => (
            <p key={participant.key} className="product-participation-list__user">{participant.userEmail} <span>Adquirió {participant.tickets} tickets</span> </p>
          ))}
          </div>


    </div>
  );


};

export default ProductParticipationList;

