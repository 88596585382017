import React from "react";

import WinnerSection from "./WinnerSection";
import { DateTime } from "luxon";
import "./Counter.css";


function calculateTimeLeft(lottery) {
  const lotteryDate = lottery;

  let start = DateTime.fromISO(DateTime.now());
  var end = DateTime.fromISO(lotteryDate);

  const diffinmili = end.diff(start);
 
  const diff = end.diff(start, ['months', 'days', 'hours', 'minutes', 'seconds'])

  //const difference = lotteryDate - +new Date();

  let timeLeft = {};
  if (diffinmili > 0) {
    timeLeft = {
      months: ("0" + diff.months).slice(-2),
      days: ("0" + diff.days).slice(-2),
      hours: ("0" + diff.hours).slice(-2),
      minutes: ("0" + diff.minutes).slice(-2),
      seconds: ("0" + Math.floor(diff.seconds)).slice(-2),
    };
  } else {
    timeLeft = 0;
  }

  return timeLeft;
}

const Counter = (props) => {
  const [timeLeft, setTimeLeft] = React.useState(
    calculateTimeLeft(props.lotterydate)
  );

  React.useEffect(() => {

    const id = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(props.lotterydate));
    }, 1000);

    return () => {
      clearTimeout(id);
    };
  });

  return (
    <div className="user-counter-section__container">
      {timeLeft === 0 ? (
        <WinnerSection
          winner={props.winner}
          userName={props.userName}
          title={props.title}
          isWinner={props.isWinner}
          showSpinner={props.showSpinner}
        />
      ) : (
        <React.Fragment>
          <h2 className="user-counter-section__title">
            El sorteo se realizara en ...
          </h2>

          <div className="user-counter-section__number-container">
            <p className="user-counter-section__number">{timeLeft.months}:</p>
            <p className="user-counter-section__number">{timeLeft.days}:</p>
            <p className="user-counter-section__number">{timeLeft.hours}:</p>
            <p className="user-counter-section__number">{timeLeft.minutes}:</p>
            <p className="user-counter-section__number user-counter-section__number-fixed">{timeLeft.seconds} </p>
            <p>Meses</p>
            <p>Días</p>
            <p>Horas</p>
            <p>Minutos</p>
            <p>Segundos</p>
          </div>

          <h3>
            Nos comunicaremos con el ganador o la ganadora del premio mediante
            el mail ingresado en su cuenta.{" "}
            <span className="user-counter-section__title">
              Por favor, no olivdes de revisar la carpeta de Spam.
            </span>
          </h3>
        </React.Fragment>
      )}
    </div>
  );
};

export default Counter;
