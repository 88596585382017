import React from "react";

import { BiTrophy } from "react-icons/bi";
import "./UserBox.css";

const UserBox = (props) => {
    return (
        <div className="userbox__container">
            <React.Fragment>
                <div className="userbox__trophy--icon__containder">
                    <BiTrophy className="userbox__trophy--icon" />
                </div>
                <p className="userbox__text">
                    Hola {props.userName}, tenés un total de {props.tickets}{" "}
                    {props.tickets > 0 ? "tickets" : "ticket"}, es decir una
                    probabilidad de {props.probability}% de ganar este premio.
                    Pero recuerda que entre más tickets compras más chances
                    tenés de ganar.
                    {props.state === "active" && (
                        <span>
                            <br />
                            <br />
                            <span>Comprá más tickets y ayuda a tu suerte!</span>
                        </span>
                    )}
                </p>
            </React.Fragment>
        </div>
    );
};

export default UserBox;
