import React, { useEffect, useState } from "react";

import BannerCategory from "../../../shared/components/UIElements/BannerCategory";
import NotificationList from "../components/NotificationList";
import GenericModal from "../../../shared/components/UIElements/GenericModal";
import LoadingSpinner from "../../../shared/components/UIElements/LoadingSpinner";
import { useHttpClient } from "../../../shared/hooks/http-hook";

const UserNotifications = () => {
    const [loadedNotifications, setLoadedNotifications] = useState();
    const { isLoading, error, sendRequest, clearError } = useHttpClient();

    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                const responseData = await sendRequest(
                    `${process.env.REACT_APP_API_URL}/api/notifications`
                );
                setLoadedNotifications(responseData.notifications);
            } catch (err) {}
        };
        fetchNotifications();
    }, [sendRequest]);

    const notificationDeletedHandler = (deletedNotificationId) => {
        setLoadedNotifications((prevNotification) =>
            prevNotification.filter(
                (notification) => notification._id !== deletedNotificationId
            )
        );
    };
    return (
        <React.Fragment>
            <GenericModal
                message={error}
                show={!!error}
                error={true}
                title={"Ocurrió un error"}
                onClear={clearError}
            />
            {isLoading && <LoadingSpinner />}
            <BannerCategory title="Administración de Notificaciones" />
            {!isLoading && loadedNotifications && (
                <NotificationList
                    items={loadedNotifications}
                    onDeleteNotification={notificationDeletedHandler}
                />
            )}
        </React.Fragment>
    );
};

export default UserNotifications;