import React, { createContext, useState, useEffect, useContext } from "react";

import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "./AuthContext";
export const NotificationContext = createContext();

const NotificationContextProvider = ({ children }) => {
  const { sendRequest } = useHttpClient();
  const [loadedNotifications, setLoadedNotifications] = useState([]);
  const [notReadedNotifications, setNotReadedNotifications] = useState([]);

  const auth = useContext(AuthContext);

  // FETCH USER NOTIFICATIONS
  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_API_URL}/api/notifications/user/${auth.userId}`
        );
        console.log("notif", responseData.notifications);
        setLoadedNotifications(responseData.notifications);
        setNotReadedNotifications(responseData.notReadedNotifications);
      } catch (err) {}
    };
    fetchNotifications();
  }, [sendRequest, auth.userId]);

  const contextValues = {
    loadedNotifications,
    notReadedNotifications,
    setNotReadedNotifications
  };

  return (
    <NotificationContext.Provider value={contextValues}>
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationContextProvider;
