import React, { useState, useContext } from "react";

import { Link } from "react-router-dom";
import Card from "../../../shared/components/UIElements/Card";
import GenericModal from "../../../shared/components/UIElements/GenericModal";
import Button from "../../../shared/components/FormElements/Button";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import { Modal } from "react-bootstrap";
import { AuthContext } from "../../../shared/context/AuthContext";

import "./NotificationList.css";

let iditem;

const NotificationList = (props) => {
  const { error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const showDeleteWarningHandler = () => {
    setShowConfirmModal(true);
  };

  const cancelDeleteHandler = () => {
    setShowConfirmModal(false);
  };

  const confirmDeleteHandler = async () => {
    setShowConfirmModal(false);

    try {
      await sendRequest(
        `${process.env.REACT_APP_API_URL}/api/notifications/${iditem}`,
        "DELETE",
        null,
        { Authorization: "Bearer " + auth.token }
      );
      props.onDeleteNotification(iditem);
    } catch (err) {}
  };

  if (props.items.length === 0) {
    return (
      <div className="notification-list center">
        <Card>
          <h2>No hay notificaciones.</h2>
          <Button
            to="/backend/notifications/new"
            className="notification-form__back-button "
          >
            Crear
          </Button>
        </Card>
      </div>
    );
  }

  return (
    <React.Fragment>
      <GenericModal
        message={error}
        show={!!error}
        error={true}
        title={"Ocurrió un error"}
        onClear={clearError}
      />
      <Modal
        show={showConfirmModal}
        onCancel={cancelDeleteHandler}
        header="Esta seguro?"
      >
        <Modal.Header closeButton>
          <Modal.Title>Esta seguro?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Quiere borrar esta notificación?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button inverse status="active" onClick={cancelDeleteHandler}>
            CANCELAR
          </Button>
          <Button danger onClick={confirmDeleteHandler}>
            BORRAR
          </Button>
        </Modal.Footer>
      </Modal>

      <Link to="/backend/notifications/new">
        <p className="notification-list__button">Crear nueva Notificación</p>
      </Link>
      <div className="users-list__container">
        <table className="users-list__container-table">
          <thead>
            <tr>
              <th>Title</th>
              <th>Description</th>
              <th>Fecha</th>
              <th>Link Interno</th>
              <th>Link Externo</th>
              <th>Imagen</th>
              <th>Icono</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {props.items.map((item) => (
              <tr key={item._id}>
                <td>{item.title}</td>
                <td>{item.description}</td>

                <td>{new Date(item.date).toDateString()}</td>
                <td>{item.intLink ? item.intLink : "-"}</td>
                <td>{item.extLink ? item.extLink : "-"}</td>
                <td>
                  <img
                    src={`${process.env.REACT_APP_API_URL}/${item.image}`}
                    alt={item.title}
                    width="150px"
                  />
                </td>
                <td>
                  {" "}
                  <img
                    src={`${process.env.REACT_APP_API_URL}/${item.icon}`}
                    alt={item.title}
                    width="150px"
                  />
                </td>
                <td>
                  <Button
                    status="active"
                    to={`/backend/notifications/${item._id}`}
                  >
                    EDITAR
                  </Button>
                  <Button
                    danger
                    onClick={() => {
                      iditem = item._id;
                      showDeleteWarningHandler();
                    }}
                  >
                    BORRAR
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};

export default NotificationList;
