import React from "react";

import './AboutUsText.css'

const AboutUsText = () =>{

    return(
    <div className="aboutus__content">
        <h3 className="aboutus__title">Big<span className="aboutus__title-color">Time</span></h3>
        <p className="aboutus__subcontent" >Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quaerat nihil sapiente aut assumenda nemo ad, saepe ipsam, dolore illo veritatis optio. Amet earum dolores, optio consectetur corrupti quibusdam. Rem, corporis?</p>
        <h3 className="aboutus__title--big"><span className="aboutus__title--bigcolor">Con </span>BigTime<span className="aboutus__title--bigcolor"> ganamos todos.</span></h3>
        <p className="aboutus__subcontent" >Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aut saepe dicta ipsam deserunt ut explicabo recusandae itaque esse, earum fuga et consectetur rerum magni aspernatur. Blanditiis quis adipisci eos voluptates.</p>
        <h3 className="aboutus__title">Nuestra filosofía</h3>
        <p className="aboutus__subcontent" >Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aut saepe dicta ipsam deserunt ut explicabo recusandae itaque esse, earum fuga et consectetur rerum magni aspernatur. Blanditiis quis adipisci eos voluptates.</p>
        
        <p className="aboutus__subcontent--bullet" >Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aut saepe dicta ipsam deserunt ut explicabo recusandae itaque esse, earum fuga et consectetur rerum magni aspernatur. Blanditiis quis adipisci eos voluptates.</p>
    
        <p className="aboutus__subcontent--bullet" >Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aut saepe dicta ipsam deserunt ut explicabo recusandae itaque esse, earum fuga et consectetur rerum magni aspernatur. Blanditiis quis adipisci eos voluptates.</p>

        <p className="aboutus__subcontent" >Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aut saepe dicta ipsam deserunt ut explicabo recusandae itaque esse, earum fuga et consectetur rerum magni aspernatur. Blanditiis quis adipisci eos voluptates.</p>
        
    </div>



    );

};

export default AboutUsText;