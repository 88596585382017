import React from "react";

import "./BannerVideo.css";

const BannerVideo = (props) => {

    return(
        <div class="video__container">
            <iframe class="video__media" width="560" title={props.src} height="315" src={props.src} frameborder="0" allowfullscreen></iframe>
        </div>
    );
};

export default BannerVideo;