import React from "react";

import './TermsConditions.css'

const TermsConditions = () =>{

    return(
    <div className="legal__content">
        <h3 className="legal__title">Política de gestión de Calidad</h3>
        <p className="legal__subcontent" >Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quaerat nihil sapiente aut assumenda nemo ad, saepe ipsam, dolore illo veritatis optio. Amet earum dolores, optio consectetur corrupti quibusdam. Rem, corporis?</p>
        <p className="legal__subcontent" >Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aut saepe dicta ipsam deserunt ut explicabo recusandae itaque esse, earum fuga et consectetur rerum magni aspernatur. Blanditiis quis adipisci eos voluptates.</p>
        <h3 className="legal__title">Política de Privacidad de BigTime</h3>
        <h4 className="legal__sub-title">I. Objeto</h4>
        <p className="legal__subcontent" >Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aut saepe dicta ipsam deserunt ut explicabo recusandae itaque esse, earum fuga et consectetur rerum magni aspernatur. Blanditiis quis adipisci eos voluptates.</p>
        <h4 className="legal__sub-title">II. Definición de Terminos y Condiciones</h4>
        <p className="legal__subcontent" >Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aut saepe dicta ipsam deserunt ut explicabo recusandae itaque esse, earum fuga et consectetur rerum magni aspernatur. Blanditiis quis adipisci eos voluptates.</p>
        <h4 className="legal__sub-title">III. Principios rectores para el tratamiento de datos</h4>
        <p className="legal__subcontent" >Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aut saepe dicta ipsam deserunt ut explicabo recusandae itaque esse, earum fuga et consectetur rerum magni aspernatur. Blanditiis quis adipisci eos voluptates.</p>
        <h4 className="legal__sub-title">IV. Cesión de datos y transferencia internacional.</h4>
        <p className="legal__subcontent" >Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aut saepe dicta ipsam deserunt ut explicabo recusandae itaque esse, earum fuga et consectetur rerum magni aspernatur. Blanditiis quis adipisci eos voluptates.</p>
        <h4 className="legal__sub-title">V. Cookies</h4>
        <p className="legal__subcontent" >Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aut saepe dicta ipsam deserunt ut explicabo recusandae itaque esse, earum fuga et consectetur rerum magni aspernatur. Blanditiis quis adipisci eos voluptates.</p>
    </div>



    );

};

export default TermsConditions;