import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Accordion, AccordionContext, Card } from "react-bootstrap";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";

import { CartContext } from "../../shared/context/CartContext";
import { AuthContext } from "../../shared/context/AuthContext";

import Button from "../../shared/components/FormElements/Button";
import CartProductsContainer from "../components/CartProductsContainer";
import AuthModal from "../../user/components/AuthModal";


import "./CartPage.css";

const CartPage = () => {
  const auth = useContext(AuthContext);
  const [isModalLogin, setIsModalLogin] = useState(null);
  const [showAuth, setShowAuth] = useState(false);
  const handleCloseAuth = () => setShowAuth(false);

  const { total, cartItems, itemCount, clearCart, checkout, handleCheckout } =
    useContext(CartContext);

    const handleShowLogin = () => {
        setIsModalLogin(true);
        setShowAuth(true);
      };

  const ContextAwareToggle = ({ children, eventKey, callback }) => {
    const currentEventKey = useContext(AccordionContext);

    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey)
    );

    const isCurrentEventKey = currentEventKey === eventKey;

    return (
      <Card.Header
        className={isCurrentEventKey ? "cart-page__faq-accordion--active" : ""}
        onClick={decoratedOnClick}
      >
        <div className="d-flex justify-content-between">
          <div>{children}</div>
          <div
            className={
              isCurrentEventKey
                ? "cart-page__faq-accordion--inactive"
                : "cart-page__faq-accordion--active"
            }
          >
            {isCurrentEventKey ? "-" : "+"}
          </div>
        </div>
      </Card.Header>
    );
  };

  return (
    <div className="cart-page">
      <div className="cart-page__banner">Mi Carrito</div>
      {itemCount === 0 && (
        <div className="cart-page__products-empty">Tu carrito está vacío</div>
      )}
      {checkout && (
        <div className="cart-page__products-checkout">Checkout exitoso</div>
      )}

      <div className="cart-page__content">
      <AuthModal
        show={showAuth}
        onHide={handleCloseAuth}
        isModalLogin={isModalLogin}
        title={isModalLogin ? "Iniciá sesión para continuar" : "¡Registrate para continuar!"}
        setIsModalLogin={setIsModalLogin}
      />
        <CartProductsContainer />
        {cartItems.length > 0 && (
          <div className="cart-page__sidebar">
            <div className="cart-page__checkout">
              <h4 className="cart-page__checkout-title">Resumen de Compra</h4>
              <div className="cart-page__checkout-items">
                {cartItems.map((item) => {
                  return (
                    <div
                      key={item.id}
                      className="cart-page__checkout-row cart-page__checkout-row--bordered"
                    >
                      <h4 className="cart-page__checkout-subtitle cart-page__checkout-subtitle--orange">
                        {item.title}
                      </h4>
                      <h4 className="cart-page__checkout-subtitle">
                        Subtotal: ${item.quantity * item.price}
                      </h4>
                    </div>
                  );
                })}
              </div>
              <div className="cart-page__checkout-totals">
                <div className="cart-page__checkout-row">
                  <h4 className="cart-page__checkout-subtitle">Total Items</h4>
                  <h3 className="cart-page__checkout-total">{itemCount}</h3>
                </div>
                <div className="cart-page__checkout-row">
                  <h4 className="cart-page__checkout-subtitle">Total:</h4>
                  <h3 className="cart-page__checkout-total">${total}</h3>
                </div>
              </div>
              <div>
                <div className="cart-page__checkout-actions">
                  <Button
                    type="button"
                    status="active"
                    className="main-button--squared-big main-button--full-width"
                    onClick={auth.isLoggedIn ? handleCheckout : handleShowLogin}
                  >
                    Finalizar Compra
                  </Button>
                  <Link to="/">
                    <button
                      type="button"
                      className="cart-page__link cart-page__link--orange"
                    >
                      Seguir Comprando
                    </button>
                  </Link>

                  <button
                    type="button"
                    className="cart-page__link"
                    onClick={clearCart}
                  >
                    Vaciar carrito
                  </button>
                </div>
              </div>
            </div>
            <div className="cart-page__coupons">
              <h4 className="cart-page__checkout-title cart-page__checkout-title--orange">
                ¡Tengo un cupón de descuento!
              </h4>
              <div className="cart-page__checkout-row">
                <input
                  type="coupon"
                  name="coupon"
                  placeholder="Ingresa tu cupón"
                  id="cart-page__discount-coupon"
                  className="cart-page__coupon-input"
                />
                <Button
                  type="button"
                  status="active"
                  className="main-button--squared-small"
                >
                  Aplicar
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>

      <Accordion className="cart-page__faq-accordion">
        <h3 className="cart-page__faq-accordion-title">
          Preguntas frecuentes:
        </h3>
        <Card>
          <ContextAwareToggle eventKey="0">
            ¿Cuándo es el sorteo?
          </ContextAwareToggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>Lorem Ipsum</Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <ContextAwareToggle eventKey="1">
            ¿Cómo es el sorteo?
          </ContextAwareToggle>
          <Accordion.Collapse eventKey="1">
            <Card.Body>Lorem Ipsum</Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <ContextAwareToggle eventKey="2">
            ¿Cómo se que gané el premio?
          </ContextAwareToggle>
          <Accordion.Collapse eventKey="2">
            <Card.Body>Lorem Ipsum</Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  );
};

export default CartPage;
