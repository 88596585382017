import React from "react";
import { useState, useEffect } from 'react';

import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import "./WinnerSection.css";

const WinnerSection = (props) => {

  const [show, setShow] = useState(props.showSpinner)

  // On componentDidMount set the timer
  useEffect(() => {
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      setShow(false)
    }, 4000)

    return () => {
      clearTimeout(timeId)
    }
  }, []);

  // If show is false the component will return null and stop here
  if (!show) {
    return (
      <div className="winner-section__container">
        {
          (!props.isWinner ? (
            <React.Fragment>
              <h2 className="winner-section__title">
                ¡Y el ganador o ganadora es!
              </h2>
              <h3 className="winner-section__winner">{props.winner.email}</h3>
              <p className="winner-section__message">
                {props.userName}, sentimos que no pudieras ganar la{" "}
                <span className="winner-section__title">{props.title}</span>, pero
                no dejes de participar que el próximo ganador o la próxima
                ganadora podés ser vos
              </p>
            </React.Fragment>
          ) :
          (
            <React.Fragment>
              <h2 className="winner-section__title">
                ¡Felicitaciones {props.userName} ganaste el {props.title}!
              </h2>
              <p className="winner-section__message">
                Se ha enviado la instrucciones para recibir el premio al mail
                registrado en su cuenta.
              </p>
            </React.Fragment>
          ))
        }
      </div>
    );
  }

  // If show is true this will be returned
  return (
    <React.Fragment>
    <h2 className="winner-section__title-lottery">
    Sorteando...
    </h2>
    <LoadingSpinner />
    </React.Fragment>
  )

};

export default WinnerSection;
