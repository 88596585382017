import React, { useContext, useState, useEffect } from "react";
import { CartContext } from "../../context/CartContext";

import Button from "../FormElements/Button";

import "./PillboxCounter.css";

const PillboxCounter = (props) => {
    const [counter, setCounter] = useState(1);
    const [maxBuyableTickets, setMaxBuyableTickets] = useState(0);

    const { increase, decrease, removeProduct, addProduct, cartItems } =
        useContext(CartContext);

    const isInCart = !!cartItems.find((item) => item.id === props.id);

    useEffect(() => {
        setMaxBuyableTickets(
            props.maxTickets -
                props.tickets -
                (isInCart &&
                    cartItems.find((item) => item.id === props.id).quantity)
        );
    }, [cartItems, props, isInCart]);

    const cartIncreaseHandler = (props) => {
        if (maxBuyableTickets <= 0) {
            alert("No hay stock!");
        } else {
            increase({ ...props, counter: 1 });
        }
    };

    const productIncreaseHandler = () => {
        if (maxBuyableTickets <= counter) {
            alert("No hay stock!");
        } else {
            setCounter(counter + 1);
        }
    };

    if (props.source === "cart") {
        return (
            <div
                className={`cart-item__count-pillbox cart-item__count-pillbox--${props.size}`}
            >
                {props.quantity > 1 && (
                    <button
                        className={`cart-item__count-pillbox-minus cart-item__count-pillbox-minus--${props.size}`}
                        onClick={() => decrease(props)}
                    >
                        -
                    </button>
                )}

                {props.quantity === 1 && (
                    <button
                        className={`cart-item__count-pillbox-minus cart-item__count-pillbox-minus--${props.size}`}
                        onClick={() => removeProduct(props)}
                    >
                        -
                    </button>
                )}
                <button
                    className={`cart-item__count-pillbox-quantity cart-item__count-pillbox-quantity--${props.size}`}
                >
                    {props.quantity}
                </button>
                <button
                    className={`cart-item__count-pillbox-plus cart-item__count-pillbox-plus--${props.size}`}
                    onClick={() => cartIncreaseHandler(props)}
                >
                    +
                </button>
            </div>
        );
    } else if (props.source === "productpage") {
        return (
            <React.Fragment>
                <div className="cart-item__count-pillbox">
                    <button
                        className="cart-item__count-pillbox-minus"
                        onClick={() => {
                            if (counter > 1) {
                                setCounter(counter - 1);
                            }
                        }}
                    >
                        -
                    </button>
                    <button className="cart-item__count-pillbox-quantity">
                        {counter}
                    </button>
                    <button
                        className="cart-item__count-pillbox-plus"
                        onClick={() => productIncreaseHandler()}
                    >
                        +
                    </button>
                </div>
                <div>
                    {!isInCart && (
                        <Button
                            status={
                                maxBuyableTickets === 0
                                    ? "inactive"
                                    : props.status
                            }
                            onClick={() => {
                                addProduct({ ...props, counter });
                                setCounter(1);
                            }}
                        >
                            Lo quiero!
                        </Button>
                    )}
                    {isInCart && (
                        <Button
                            status={
                                maxBuyableTickets === 0
                                    ? "inactive"
                                    : props.status
                            }
                            onClick={() => {
                                increase({ ...props, counter });
                                setCounter(1);
                            }}
                        >
                            Agregar más
                        </Button>
                    )}
                </div>
            </React.Fragment>
        );
    }
};

export default PillboxCounter;
