import React, { useContext } from "react";
import { NavDropdown } from "react-bootstrap";
import { NotificationContext } from '../../context/NotificationContext';
    import "./NotificationContainerNav.css";




const NotificationContainerNav = () => {
    
    const { loadedNotifications } = useContext(NotificationContext); 

    const linknotification = (notificationvalue) => {

        let link = "";
            if (notificationvalue.isExtLink){
                link = notificationvalue.extLink;
            }else{
                link = notificationvalue.intLink; 
            }

    return link;
    }

    if (loadedNotifications.length === 0) {
        return (
            <div className="notification-nav__container">
                    No tienes notificaciones
            </div>
        );
    }

    return (
        
        <div className="notification-nav__container">
            {loadedNotifications.length > 0 &&
                loadedNotifications.map((notification) => (
                    
                    
                 <NavDropdown.Item key={notification._id} className="notification-nav__link" href={linknotification(notification)} target="_blank"> <div className="notification-nav__item-container">
                    <div className="notification-nav__icon">
                    <img src={`${process.env.REACT_APP_API_URL}/${notification.icon}`} width="100%" alt="" />
                    </div>
                    <div className="notification-nav__content">
                    <h4>{notification.title}</h4>
                     <p className="cart-nav-item__bullet-points">{notification.description}</p>
                    {notification.image && <img src={`${process.env.REACT_APP_API_URL}/${notification.image}`} width="100%" alt="" />} 
                    </div>
                    
                 </div>
                 </NavDropdown.Item> 
                ))}
           
        </div>
    );
};

export default NotificationContainerNav;
