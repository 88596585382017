import React, { useContext } from "react";

import { CartContext } from "../../shared/context/CartContext";
import { GoTrashcan } from "react-icons/go";

import PillboxCounter from "../../shared/components/FormElements/PillboxCounter";

import "./CartItem.css";

const CartItem = ({ product }) => {
    const { removeProduct } = useContext(CartContext);

    return (
        <div className="cart-item">
            <img
                className="cart-item__image"
                alt={product.name}
                src={`${process.env.REACT_APP_API_URL}/${product.imageUrl[0]}`}
            />
            <div className="cart-item__content">
                <h4 className="cart-item__title">{product.title}</h4>
                <div className="cart-item__content-middle">
                    <div className="cart-item__bullet-points">
                        <p>- Válido hasta el {product.expiration}</p>
                    </div>
                    <button
                                className="cart-item__trash"
                                onClick={() => removeProduct(product)}
                            >
                                <GoTrashcan/>

                            </button>
                </div>

                <div className="cart-item__content-bottom">
                    <PillboxCounter {...product} source="cart"/>
                    <div className="cart-item__pricing">${product.price}</div>

                    <div className="cart-item__subtotal">
                        Subtotal: ${product.quantity * product.price}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CartItem;
