import React from "react";

import BannerLegal from "../../shared/components/UIElements/BannerLegal";
import BannerVideo from "../../shared/components/UIElements/BannerVideo";
import AboutUsText from "../components/AboutUsText";

const LegalPage = () => {


    return (
        <div>
            <BannerLegal title='Nosotros' />
            <BannerVideo src='https://www.youtube.com/embed/b7270W6-lqA' />
            <AboutUsText/>
        </div>

    );
};

export default LegalPage;