import React, { useContext, useEffect, useState } from "react";

import OrdersList from "../components/OrdersList";
import GenericModal from "../../../shared/components/UIElements/GenericModal";
import LoadingSpinner from "../../../shared/components/UIElements/LoadingSpinner";
import BannerCategory from "../../../shared/components/UIElements/BannerCategory";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import { AuthContext } from "../../../shared/context/AuthContext";

const Orders = () => {
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [loadedOrders, setLoadedOrders] = useState();
  const [loggedUser, setLoggedUser] = useState(null);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_API_URL}/api/orders`,
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );
        setLoadedOrders(responseData.orders);
        console.log(responseData.orders);
      } catch (err) {}
    };
    fetchOrders();
  }, [sendRequest,auth.token]);

  const orderDeletedHandler = (deletedOrderId) => {
    setLoadedOrders((prevOrder) =>
      prevOrder.filter((order) => order.id !== deletedOrderId)
    );
  };

  useEffect(() => {
    const fetchUser = async () => {
      if (auth.userId) {
        try {
          const responseData = await sendRequest(
            `${process.env.REACT_APP_API_URL}/api/users/${auth.userId}`, "GET",
            null,
            { Authorization: "Bearer " + auth.token }
          );
          setLoggedUser(responseData.user);
        } catch (err) {}
      } else {
        setLoggedUser(null);
      }
    };
    fetchUser();
  }, [auth.login, auth.logout, auth.userId, sendRequest]);

  return (
    <React.Fragment>
      <GenericModal
        message={error}
        show={!!error}
        error={true}
        title={"Ocurrió un error"}
        onClear={clearError}
      />
      {isLoading && (
        <div className="center">
          <LoadingSpinner />
        </div>
      )}
      <BannerCategory title="Listado de Ordenes" />
      {!isLoading && loadedOrders && (
        <OrdersList
          orderslist={loadedOrders}
          user={loggedUser}
          onDeleteOrder={orderDeletedHandler}
        />
      )}
    </React.Fragment>
  );
};

export default Orders;
