import React from "react";
import { Carousel } from "react-bootstrap";
import { BiRightArrowAlt } from "react-icons/bi";

import Button from "../../shared/components/FormElements/Button";

import "./BannerHome.css";

const BannerHome = (props) => {
  return (
    <Carousel
      fade
      className="home-carousel__section"
      indicators={false}
      controls={props.items.length === 1 ? false : true}
    >
      {props.items.map((item) => (
        <Carousel.Item
          id={item.id}
          key={item.id}
          className="home-carousel__items"
        >
          <div className="home-carousel--gradient">
            <img
              className="home-carousel__image"
              src={`${process.env.REACT_APP_API_URL}/${item.image}`}
              alt="First slide"
            />
          </div>
          <Carousel.Caption className="home-carousel__text">
            <h3>{item.title}</h3>
            <p>{item.description}</p>
            {item.isExtLink ? (
              <Button
                target="_blank"
                className="banner-button"
                status="inverse"
                href={`//${item.extLink}`}
              >
                Ver más
                <div className="d-inline-block">
                  <BiRightArrowAlt className="home-banner__arrow__icon" />
                </div>
              </Button>
            ) : (
              <Button
                className="banner-button"
                status="inverse"
                to={item.intLink}
              >
                Ver más
                <div className="d-inline-block">
                  <BiRightArrowAlt className="home-banner__arrow__icon" />
                </div>
              </Button>
            )}
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default BannerHome;
