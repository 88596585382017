import React from "react";
import { Link } from "react-router-dom";
import {
  BiCart,
  BiCommentAdd,
  BiPackage,
  BiSpreadsheet,
  BiTrophy,
  BiUser,
  BiWindows,
} from "react-icons/bi";

import BannerCategory from "../../../shared/components/UIElements/BannerCategory";

import "./Home.css";

const BackendHome = () => {
  return (
    <React.Fragment>
      <BannerCategory title="Backend" />
      <div className="backend-home__container">
        <div className="backend-home__section-container">
          <div className="backend-home__section-container__top">
            <h4 className="backend-home__section-title">
              <BiUser className="backend-home__section-icon" />
              Administración de Usuarios
            </h4>
          </div>
          <div className="backend-home__section-container__bottom">
            <Link to="/backend/users">
              <p>Ver Listado / Editar</p>
            </Link>
          </div>
        </div>
        <div className="backend-home__section-container">
          <div className="backend-home__section-container__top">
            <h4 className="backend-home__section-title">
              <BiPackage className="backend-home__section-icon" />
              Administración de Productos
            </h4>
          </div>
          <div className="backend-home__section-container__bottom">
            <Link to="/backend/products">
              <p>Ver/Editar Productos</p>
            </Link>
            <Link to="/backend/products/new">
              {" "}
              <p>Agregar Producto</p>
            </Link>
          </div>
        </div>
        <div className="backend-home__section-container">
          <div className="backend-home__section-container__top">
            <h4 className="backend-home__section-title">
              <BiWindows className="backend-home__section-icon" />
              Administración de MainSliders
            </h4>
          </div>
          <div className="backend-home__section-container__bottom">
            <Link to="/backend/mainsliders">
              <p>Ver/Editar MainSlider</p>
            </Link>
            <Link to="/backend/mainsliders/new">
              <p>Crear nuevo Mainslider</p>
            </Link>
          </div>
        </div>
        <div className="backend-home__section-container">
          <div className="backend-home__section-container__top">
            <h4 className="backend-home__section-title">
              <BiSpreadsheet className="backend-home__section-icon" />
              Categorias
            </h4>
          </div>
          <div className="backend-home__section-container__bottom">
            <Link to="/backend/categories">
              <p>Ver Listado / Editar</p>
            </Link>
            <Link to="/backend/categories/new">
              <p>Crear Nueva Categoria</p>
            </Link>
          </div>
        </div>
        <div className="backend-home__section-container">
          <div className="backend-home__section-container__top">
            <h4 className="backend-home__section-title">
              <BiCommentAdd className="backend-home__section-icon" />
              Notificaciones
            </h4>
          </div>
          <div className="backend-home__section-container__bottom">
          <Link to="/backend/notifications">
              <p>Ver Notificaciones</p>
            </Link>
            <Link to="/backend/notifications/new">
              <p>Crear Notificacion Push</p>
            </Link>
            <Link to="">
              <p>Crear Notificacion Mail</p>
            </Link>
          </div>
        </div>
        <div className="backend-home__section-container">
          <div className="backend-home__section-container__top">
            <h4 className="backend-home__section-title">
              <BiTrophy className="backend-home__section-icon" />
              Ganadores
            </h4>
          </div>
          <div className="backend-home__section-container__bottom">
            <Link to="/backend/winners">
              <p>Ver Listado</p>
            </Link>
          </div>
        </div>
        <div className="backend-home__section-container">
          <div className="backend-home__section-container__top">
            <h4 className="backend-home__section-title">
              <BiCart className="backend-home__section-icon" />
              Compras
            </h4>
          </div>
          <div className="backend-home__section-container__bottom">
            <Link to="/backend/orders">
              <p>Ver Listado</p>
            </Link>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BackendHome;
