import React from 'react';
import { Link } from "react-router-dom";
import './CategoryCard.css';

const CategoryCard = props => {
    return (
        <Link to={{
            pathname: '/products',
            state: {
            categoryRoute: props.title,
            priceRoute: "Desde $0",
            progressRoute: "Desde 0%",
            },
            
            }}>
        <div className='category-card'>
            <div className="category-card--fadeout"></div>
            <img alt="" src={`${process.env.REACT_APP_API_URL}/${props.image}`} className="category-card__img" />
            <div className="category-card__content">
                {props.title}
            </div>
        </div></Link>
    );
};

export default CategoryCard;