import React, { useContext, useEffect, useState } from "react";

import WinnersList from "../components/WinnersList";
import GenericModal from "../../../shared/components/UIElements/GenericModal";
import LoadingSpinner from "../../../shared/components/UIElements/LoadingSpinner";
import BannerCategory from "../../../shared/components/UIElements/BannerCategory";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import { AuthContext } from "../../../shared/context/AuthContext";

const Winners = () => {
    const auth = useContext(AuthContext);
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const [loadedWinners, setLoadedWinners] = useState();
    const [loggedUser, setLoggedUser] = useState(null);

    useEffect(() => {
        const fetchWinners = async () => {
            try {
                const responseData = await sendRequest(
                    `${process.env.REACT_APP_API_URL}/api/winners`
                );
                setLoadedWinners(responseData.winners);
            } catch (err) {}
        };
        fetchWinners();
    }, [sendRequest]);

    const winnerDeletedHandler = (deletedWinnerId) => {
        setLoadedWinners((prevWinner) =>
            prevWinner.filter((winner) => winner.id !== deletedWinnerId)
        );
    };

    useEffect(() => {
        const fetchUser = async () => {
            if (auth.userId) {
                try {
                    const responseData = await sendRequest(
                        `${process.env.REACT_APP_API_URL}/api/users/${auth.userId}`, "GET",
                        null,
                        { Authorization: "Bearer " + auth.token }
                    );
                    setLoggedUser(responseData.user);
                } catch (err) {}
            } else {
                setLoggedUser(null);
            }
        };
        fetchUser();
    }, [auth.login, auth.logout, auth.userId, sendRequest]);

    return (
        <React.Fragment>
            <GenericModal
                message={error}
                show={!!error}
                error={true}
                title={"Ocurrió un error"}
                onClear={clearError}
            />
            {isLoading && (
                <div className="center">
                    <LoadingSpinner />
                </div>
            )}
            <BannerCategory title="Listado de Ganadores" />
            {!isLoading && loadedWinners && (
                <WinnersList
                    winnerslist={loadedWinners}
                    user={loggedUser}
                    onDeleteWinner={winnerDeletedHandler}
                />
            )}
        </React.Fragment>
    );
};

export default Winners;
