import React, { useContext } from "react";
import { Accordion, AccordionContext, Card } from "react-bootstrap";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";

import BannerLegal from "../../shared/components/UIElements/BannerLegal";
import HelpForm from "../components/HelpPageForm";
import HelpPageText from "../components/HelpPageText";
import "./HelpPage.css"


const HelpPage = () =>{

    const ContextAwareToggle = ({ children, eventKey, callback }) => {
        const currentEventKey = useContext(AccordionContext);

        const decoratedOnClick = useAccordionToggle(
            eventKey,
            () => callback && callback(eventKey)
        );

        const isCurrentEventKey = currentEventKey === eventKey;

        return (
            <Card.Header
                className={
                    isCurrentEventKey
                        ? "help-page__faq-accordion--active"
                        : ""
                }
                onClick={decoratedOnClick}
            >
                <div className="d-flex justify-content-between">
                    <div>{children}</div>
                    <div className={isCurrentEventKey ? "help-page__faq-accordion--inactive" : "help-page__faq-accordion--active"}>{isCurrentEventKey ? "-" : "+"}</div>
                </div>
            </Card.Header>
        );
    };

    return (
        <div>
            <BannerLegal title='Ayuda' />
            <Accordion className="help-page__faq-accordion">
                <h3 className="help-page__faq-accordion-title">
                    Preguntas frecuentes:
                </h3>
                <Card>
                    <ContextAwareToggle eventKey="0">
                        ¿Qué es BigTime?
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aut saepe dicta ipsam deserunt ut explicabo recusandae itaque esse, earum fuga et consectetur rerum magni aspernatur. Blanditiis quis adipisci eos voluptates. dolor sit amet consectetur, adipisicing elit. Aut saepe dicta ipsam deserunt ut explicabo recusandae itaque esse, earum fuga et consectetur rerum magni aspernatur. Blanditiis quis adipisci eos voluptates.</Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <ContextAwareToggle eventKey="1">
                        ¿Cómo funciona?
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="1">
                        <Card.Body>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aut saepe dicta ipsam deserunt ut explicabo recusandae itaque esse, earum fuga et consectetur rerum magni aspernatur. Blanditiis quis adipisci eos voluptates.</Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <ContextAwareToggle eventKey="2">
                        ¿Cual es el método de pago?
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="2">
                        <Card.Body>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aut saepe dicta ipsam deserunt ut explicabo recusandae itaque esse, earum fuga et consectetur rerum magni aspernatur. Blanditiis quis adipisci eos voluptates.</Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <ContextAwareToggle eventKey="3">
                        ¿Cómo sé que gané el premio?
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="3">
                        <Card.Body>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aut saepe dicta ipsam deserunt ut explicabo recusandae itaque esse, earum fuga et consectetur rerum magni aspernatur. Blanditiis quis adipisci eos voluptates.</Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <ContextAwareToggle eventKey="4">
                        ¿Puedo devolver el premio?
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="4">
                        <Card.Body>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aut saepe dicta ipsam deserunt ut explicabo recusandae itaque esse, earum fuga et consectetur rerum magni aspernatur. Blanditiis quis adipisci eos voluptates.</Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <ContextAwareToggle eventKey="5">
                        ¿Si gano el premio, puedo canjearlo por otro?
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="5">
                        <Card.Body>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aut saepe dicta ipsam deserunt ut explicabo recusandae itaque esse, earum fuga et consectetur rerum magni aspernatur. Blanditiis quis adipisci eos voluptates.</Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <ContextAwareToggle eventKey="6">
                        ¿Hay garantias / seguros?
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="6">
                        <Card.Body>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aut saepe dicta ipsam deserunt ut explicabo recusandae itaque esse, earum fuga et consectetur rerum magni aspernatur. Blanditiis quis adipisci eos voluptates.</Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
            <div className="help-page__info--container">
                <HelpPageText />
                <HelpForm />
            </div>
            
        </div>
    );

};

export default HelpPage;