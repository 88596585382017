import React from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";

import MainNavigation from "../shared/components/Navigation/MainNavigation";
import Footer from "../shared/components/Navigation/Footer";
import PrivateRoute from "../shared/helpers/PrivateRoute";
import AdminRoute from "../shared/helpers/AdminRoute";

import Home from "../home/pages/Home";
import ProductPage from "../products/pages/ProductPage";
import Purchases from "../user/pages/Purchases";
import Cart from "../products/pages/CartPage";
import Checkout from "../products/pages/CheckoutPage";
import LegalPage from "../legal/pages/LegalPage";
import HelpPage from "../help/pages/HelpPage";
import PartnersPage from "../partners/pages/PartnersPage";
import AboutUsPage from "../aboutus/pages/AboutUsPage";
import ProductSectionPage from "../products/pages/ProductSectionPage";

import BackendHome from "../backend/home/pages/Home";
import BackendUsers from "../backend/user/pages/Users";
import BackendProducts from "../backend/products/pages/ProductListPage";
import BackendNewProduct from "../backend/products/pages/NewProduct";
import BackendUpdateProduct from "../backend/products/pages/UpdateProduct";
import BackendUserMainSliders from "../backend/mainsliders/pages/UserMainSliders";
import BackendWinners from "../backend/winners/pages/Winners";
import BackendOrders from "../backend/orders/pages/Orders";
import BackendNewMainSlider from "../backend/mainsliders/pages/NewMainSlider";
import BackendUpdateMainSlider from "../backend/mainsliders/pages/UpdateMainSlider";
import BackendUserCategories from "../backend/categories/pages/UserCategories";
import BackendNewCategory from "../backend/categories/pages/NewCategory";
import BackendUpdateCategory from "../backend/categories/pages/UpdateCategory";
import BackendNewNotification from "../backend/notifications/pages/NewNotification";
import BackendUserNotifications from "../backend/notifications/pages/UserNotifications";
import BackendUpdateNotification from "../backend/notifications/pages/UpdateNotification";



//

const Routes = () => {
  let categoryRoute = "Todas";
  let priceRoute = "Desde $0";
  let progressRoute = "Desde 0%";
  const location = useLocation();

  if (location.pathname === "/products") {
    if (location.state) {
      categoryRoute = location.state.categoryRoute;
      priceRoute = location.state.priceRoute;
      progressRoute = location.state.progressRoute;
    }
  }

  return (
    <React.Fragment>
      <MainNavigation />
      <main>
        <Switch>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/products/:productId">
            <ProductPage />
          </Route>
          <PrivateRoute
            exact
            path="/users/:userId/purchases"
            component={Purchases}
          />
          <PrivateRoute exact path="/cart/checkout" component={Checkout} />
          <Route path="/cart">
            <Cart />
          </Route>
          <Route path="/products">
            <ProductSectionPage
              state={"Activo"}
              category={categoryRoute}
              price={priceRoute}
              progress={progressRoute}
            />
          </Route>
          <Route path="/legal">
            <LegalPage />
          </Route>
          <Route path="/aboutus">
            <AboutUsPage />
          </Route>
          <Route path="/help">
            <HelpPage />
          </Route>
          <Route path="/partners">
            <PartnersPage />
          </Route>
          {/*           Rutas backend
           */}
          <AdminRoute exact path="/backend" component={BackendHome} />
          <AdminRoute exact path="/backend/users" component={BackendUsers} />
          <AdminRoute
            path="/backend/products"
            component={BackendProducts}
            exact
          />
          <AdminRoute
            path="/backend/products/new"
            exact
            component={BackendNewProduct}
          />
          <AdminRoute
            path="/backend/products/:productId"
            component={BackendUpdateProduct}
          />
          <AdminRoute
            path="/backend/mainsliders"
            component={BackendUserMainSliders}
            exact
          />
          <AdminRoute
            path="/backend/mainsliders/new"
            exact
            component={BackendNewMainSlider}
          />
          <AdminRoute
            path="/backend/mainsliders/:mainsliderId"
            component={BackendUpdateMainSlider}
          />
          <AdminRoute
            path="/backend/winners"
            component={BackendWinners}
            exact
          />
            <AdminRoute
            path="/backend/orders"
            component={BackendOrders}
            exact
          />
          <AdminRoute
            path="/backend/categories"
            component={BackendUserCategories}
            exact
          />
          <AdminRoute
            path="/backend/categories/new"
            exact
            component={BackendNewCategory}
          />
          <AdminRoute
            path="/backend/categories/:categoryId"
            component={BackendUpdateCategory}
          />
          <AdminRoute
            path="/backend/notifications"
            component={BackendUserNotifications}
            exact
          />
          <AdminRoute
            exact
            path="/backend/notifications/new"
            component={BackendNewNotification}
          />
          <AdminRoute
            path="/backend/notifications/:notificationId"
            component={BackendUpdateNotification}
          />
          <Redirect to="/" />
        </Switch>
      </main>
      <Footer />
    </React.Fragment>
  );
};

export default Routes;
