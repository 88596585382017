import React from "react";

import './PartnersPageText.css'

const PartnersPageText = () =>{

    return(
    <div className="partners-page__content">
        <h2 className="partners-page__title">¿Quéres ser parte de BigTime?</h2>
        <p className="partners-page__subcontent" >Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aut saepe dicta ipsam deserunt ut explicabo recusandae itaque esse, earum fuga et consectetur rerum magni aspernatur. Blanditiis quis adipisci eos voluptates. dolor sit amet consectetur, adipisicing elit. Aut saepe dicta ipsam deserunt ut explicabo recusandae itaque esse, earum fuga et consectetur rerum magni aspernatur. Blanditiis quis adipisci eos voluptates.Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aut saepe dicta ipsam deserunt ut explicabo recusandae itaque esse, earum fuga et consectetur rerum magni aspernatur. Blanditiis quis adipisci eos voluptates. dolor sit amet consectetur, adipisicing elit. Aut saepe dicta ipsam deserunt ut explicabo recusandae itaque esse, earum fuga et consectetur rerum magni aspernatur. Blanditiis quis adipisci eos voluptates.</p>
        
        
    </div>



    );

};

export default PartnersPageText;