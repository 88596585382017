import React, { useContext } from "react";
import { Route } from "react-router-dom";
import { AuthContext } from "../../shared/context/AuthContext";
import BlankPage from "../../shared/pages/BlankPage";


const PrivateRoute = ({ component: Component, ...restOfProps }) => {
    const auth = useContext(AuthContext);

    return (
        <Route
            {...restOfProps}
            render={(props) =>
                auth.isLoggedIn ? <Component {...props} /> : <BlankPage modal={true}/>
            }
        />
    );
};

export default PrivateRoute;
