import React, { useEffect, useState } from "react";

import BannerCategory from "../../../shared/components/UIElements/BannerCategory";
import MainSliderList from "../components/MainSliderList";
import GenericModal from "../../../shared/components/UIElements/GenericModal";
import LoadingSpinner from "../../../shared/components/UIElements/LoadingSpinner";
import { useHttpClient } from "../../../shared/hooks/http-hook";

const UserMainSliders = () => {
    const [loadedMainSliders, setLoadedMainSliders] = useState();
    const { isLoading, error, sendRequest, clearError } = useHttpClient();

    useEffect(() => {
        const fetchMainSliders = async () => {
            try {
                const responseData = await sendRequest(
                    `${process.env.REACT_APP_API_URL}/api/mainsliders`
                );
                setLoadedMainSliders(responseData.mainsliders);
            } catch (err) {}
        };
        fetchMainSliders();
    }, [sendRequest]);

    const mainsliderDeletedHandler = (deletedMainSliderId) => {
        setLoadedMainSliders((prevMainSlider) =>
            prevMainSlider.filter(
                (mainslider) => mainslider.id !== deletedMainSliderId
            )
        );
    };
    return (
        <React.Fragment>
            <GenericModal
                message={error}
                show={!!error}
                error={true}
                title={"Ocurrió un error"}
                onClear={clearError}
            />
            {isLoading && <LoadingSpinner />}
            <BannerCategory title="Administración de MainSliders" />
            {!isLoading && loadedMainSliders && (
                <MainSliderList
                    items={loadedMainSliders}
                    onDeleteMainSlider={mainsliderDeletedHandler}
                />
            )}
        </React.Fragment>
    );
};

export default UserMainSliders;
