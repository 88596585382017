import React, { useContext, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";

import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import Card from "../../../shared/components/UIElements/Card";
import BannerCategory from "../../../shared/components/UIElements/BannerCategory";
import Select from "../../../shared/components/FormElements/Select";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import { AuthContext } from "../../../shared/context/AuthContext";

import "./MainSlidersForm.css";
import Button from "../../../shared/components/FormElements/Button";

const HelpSchema = Yup.object().shape(
  {
    title: Yup.string().required("Requerido"),
    description: Yup.string().required("Requerido"),
    order: Yup.string()
      .oneOf(["1", "2", "3", "4", "5", "6"])
      .required("Requerido"),
    isExtLink: Yup.boolean(),
    extLink: Yup.string().when("intLink", {
      is: (intLink) => !intLink || intLink.length === 0,
      then: Yup.string()
      .matches(
        /^(www\.)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
        "Formato: www.***.com"
      )
        .required("Requerido"),
      otherwise: Yup.string(),
    }),
    intLink: Yup.string().when("extLink", {
      is: (extLink) => !extLink || extLink.length === 0,
      then: Yup.string().required("Requerido"),
      otherwise: Yup.string(),
    }),
    image: Yup.mixed().required("Requerido"),
    status: Yup.boolean(),
  },
  [["extLink", "intLink"]]
);

const NewMainSlider = () => {
  const auth = useContext(AuthContext);
  const [isExtLinkValue, setIsExtLink] = useState(false);

  const { sendRequest, error, clearError } = useHttpClient();

  const history = useHistory();



  return (
    <React.Fragment>
      <BannerCategory title="Crear MainSlider" />
      <Formik
        initialValues={{
          title: "",
          description: "",
          order: "",
          isExtLink: isExtLinkValue,
          extLink: "",
          intLink: "",
          image: "",
          status: false,
        }}
        validateOnMount
        validationSchema={HelpSchema}
        handleChange
        onSubmit={async (values) => {
          clearError();
          try {
            const formData = new FormData();
            formData.append("title", values.title);
            formData.append("description", values.description);
            formData.append("order", values.order);
            formData.append("isExtLink", values.isExtLink);
            formData.append("intLink", values.intLink);
            formData.append("extLink", values.extLink);
            formData.append("status", values.status);
            formData.append("creator", auth.userId);
            formData.append("image", values.image);
            await sendRequest(
              `${process.env.REACT_APP_API_URL}/api/mainsliders`,
              "POST",
              formData,
              { Authorization: "Bearer " + auth.token },
            );
            history.push("/backend/mainsliders");
          } catch (err) {}
        }}
      >
        {(formProps) => (
          <div className="mainslider-form__background">
            <Card className="mainslider-form__card">
              <h2 className="mainslider-form__title--big">DATOS MAINSLIDER</h2>

              <Form className="mainslider-form">
                {error && (
                  <div className="auth-form__api-error-placeholder">
                    <div className="auth-form__api-error">{error}</div>
                  </div>
                )}
                <div className="mainslider-form__content">
                  <div className="mainslider-form__input-block mainslider-form__input-block--grid">
                    <div className="mainslider-form__input-block mainslider-form__input-block--column">
                      <div className="mainslider-form__error-placeholder mainslider-form__error-placeholder--column">
                        <ErrorMessage
                          name="title"
                          component="div"
                          className="mainslider-form__validation-error"
                        />
                      </div>
                      <Field
                        type="text"
                        name="title"
                        placeholder="Titulo"
                        className="mainslider-form__form-input mainslider-form__form-input--inline"
                      />
                    </div>
                    <div className="mainslider-form__input-block mainslider-form__input-block--column">
                      <div className="mainslider-form__error-placeholder mainslider-form__error-placeholder--column">
                        <ErrorMessage
                          name="description"
                          component="div"
                          className="mainslider-form__validation-error"
                        />
                      </div>

                      <Field
                        type="textArea"
                        name="description"
                        placeholder="Descripcion"
                        className="mainslider-form__form-input mainslider-form__form-input--inline"
                      />
                    </div>
                  </div>

                  <div className="mainslider-form__input-block mainslider-form__input-block--grid">
                    <div className="mainslider-form__input-block mainslider-form__input-block--column">
                      <div className="mainslider-form__error-placeholder">
                        <ErrorMessage
                          name="order"
                          component="div"
                          className="mainslider-form__validation-error"
                        />
                      </div>
                      <Select
                        className="mainslider-form__form-input mainslider-form__form-input--inline"
                        name="order"
                      >
                        <option value="">Orden</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4 </option>
                        <option value="5">5 </option>
                        <option value="6">6 </option>
                      </Select>
                    </div>
                    <div className="mainslider-form__input-block mainslider-form__input-block--column">
                      <div className="mainslider-form__error-placeholder mainslider-form__error-placeholder--column">
                        <label>Link externo?</label>
                        <Field
                          name="isExtLink"
                          type="checkbox"
                          className="mainslider-form__form-input mainslider-form__form-input--inline"
                          onClick={() => {
                            setIsExtLink(!isExtLinkValue)
                            formProps.setFieldValue(
                            "isExtLink",
                            isExtLinkValue
                            );
                          }}
                        />
                      </div>
                      {isExtLinkValue === false ? (
                        <React.Fragment>
                          <ErrorMessage
                            name="intLink"
                            component="div"
                            className="mainslider-form__validation-error"
                          />
                          <Field
                            type="text"
                            name="intLink"
                            placeholder="Link interno"
                            className="mainslider-form__form-input mainslider-form__form-input--inline"
                          />
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <ErrorMessage
                            name="extLink"
                            component="div"
                            className="mainslider-form__validation-error"
                          />
                          <Field
                            type="text"
                            name="extLink"
                            placeholder="Link externo"
                            className="mainslider-form__form-input mainslider-form__form-input--inline"
                          />
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                  <div className="mainslider-form__input-block mainslider-form__input-block--grid">
                    <div className="mainslider-form__input-block mainslider-form__input-block--column">
                      <div className="mainslider-form__error-placeholder mainslider-form__error-placeholder--column">
                        <ErrorMessage
                          name="image"
                          component="div"
                          className="mainslider-form__validation-error"
                        />
                      </div>

                      <input
                        type="file"
                        name="image"
                        onChange={(event) => {
                          formProps.setFieldValue(
                            "image",
                            event.target.files[0]
                          );
                        }}
                      />
                    </div>
                    <div className="mainslider-form__error-placeholder mainslider-form__error-placeholder--column">
                      <label>Activo</label>
                      <Field
                        type="checkbox"
                        name="status"
                        className="mainslider-form__form-input mainslider-form__form-input--inline"
                      />
                    </div>
                  </div>
                  <div className="help-form__footer">
                    <div className="help-form__button-block--grid"></div>
                    <Button
                      className="main-button--squared-bigger main-button--80-width"
                      type="submit"
                    >
                      Enviar
                    </Button>
                  </div>
                </div>
              </Form>
            </Card>
          </div>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default NewMainSlider;
