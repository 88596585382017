import React, { useContext, useEffect, useState } from "react";
import { useHttpClient } from "../../shared/hooks/http-hook";
import "./ProductSectionPage.css";
import ScrollContainer from "../../shared/components/UIElements/ScrollContainer";
import BannerCategory from "../../shared/components/UIElements/BannerCategory";
import Filter from "../../shared/components/UIElements/Filter";
import { ProductsContext } from "../../shared/context/ProductsContext";
//import { SearchContext } from "../../shared/context/SearchContext";
import ProductSectionContainer from "../components/ProductSectionContainer";


const ProductSectionPage = (props) => {


  const { products } = useContext(ProductsContext);
  //const { searchArray } = useContext(SearchContext);

  const { sendRequest } = useHttpClient();

  const [loadedCategories, setLoadedCategories] = useState();
  const [stateValue, setStateValue] = useState(props.state);
  const [categoryValue, setCategoryValue] = useState(props.category);
  const [priceValue, setPriceValue] = useState(props.price);
  const [progressValue, setProgressValue] = useState(props.progress);
  const [arrayLength, setArrayLength] = useState(0);
  const [productsFiltered, setProductsFiltered] = useState(products);
  const [textTitle, setTexttitle] = useState("");
  const [textDescription, setTextDescription] = useState("");

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_API_URL}/api/categories`
        );
        setLoadedCategories(responseData.categories);
      } catch (err) {}
    };
    fetchCategories();
  }, [sendRequest]);

  useEffect(() => {
    setCategoryValue(props.category);
  }, [props.category]);

  useEffect(() => {
    
    if (categoryValue !== "Todas") {
      
      if (loadedCategories){
        
        loadedCategories.forEach((item) => {
          if (categoryValue === item.title) {

            setTexttitle(item.title);
            setTextDescription(item.summary);


          }

        });
      }
    } else {
      if (priceValue !== "Desde $0") {
        switch (priceValue) {
          case "Desde $100":
            setTexttitle("Desde $100");
            setTextDescription("Las mejores ofertas!");
            break;
          case "Desde $200":
            setTexttitle("Desde $200");
            setTextDescription("Las mejores ofertas!");
            break;
          case "Desde $500":
            setTexttitle("Desde $500");
            setTextDescription("Las mejores ofertas!");
            break;
          case "Desde $1.000":
            setTexttitle("Desde $1.000");
            setTextDescription("Las mejores ofertas!");
            break;
          case "Desde $3.000":
            setTexttitle("Desde $3.000");
            setTextDescription("Las mejores ofertas!");
            break;
          case "Desde $5.000":
            setTexttitle("Desde $5.000");
            setTextDescription("Las mejores ofertas!");
            break;
          default:
            setTexttitle("Todas");
            setTextDescription("¡No te lo pierdas!");
            break;
        }
      } else {
        if (progressValue !== "Desde 0%") {
          switch (progressValue) {
            case "Desde 25%":
              setTexttitle("Desde 25%");
              setTextDescription("Las mejores ofertas!");
              break;
            case "Desde 50%":
              setTexttitle("Desde 50%");
              setTextDescription("Las mejores ofertas!");
              break;
            case "Desde 75%":
              setTexttitle("Desde 75%");
              setTextDescription("Las mejores ofertas!");
              break;
            default:
              setTexttitle("Todas");
              setTextDescription("¡No te lo pierdas!");
              break;
          }
        } else {
          setTexttitle("Todas");
          setTextDescription("¡No te lo pierdas!");
        }
      }
    }
  }, [loadedCategories,categoryValue, priceValue, progressValue]);

  useEffect(() => {
    let arrayProductsId = [];

    /// RECORRER LOS PRODUCTS Y OBTENER TODOS LOS IDS DE PRODUCTOS QUE TIENEN EL STATUS QUE QUIERO
  
    if (products){
      products.forEach((item) => {
        if (stateValue === "Activo") {
          // ACTIVOS
          let progressStatus = Math.floor((item.tickets / item.maxTickets) * 100);
  
          if (item.status === "active") {
            if (categoryValue !== "Todas") {
              if (categoryValue === item.category.title) {
                if (
                  priceValue === "Desde $0" ||
                  (priceValue === "Desde $100" && item.price >= 100) ||
                  (priceValue === "Desde $200" && item.price >= 200) ||
                  (priceValue === "Desde $500" && item.price >= 500) ||
                  (priceValue === "Desde $1.000" && item.price >= 1000) ||
                  (priceValue === "Desde $3.000" && item.price >= 3000) ||
                  (priceValue === "Desde $5.000" && item.price >= 5000)
                ) {
                  if (
                    progressValue === "Desde 0%" ||
                    (progressValue === "Desde 25%" && progressStatus >= 25) ||
                    (progressValue === "Desde 50%" && progressStatus >= 50) ||
                    (progressValue === "Desde 75%" && progressStatus >= 75)
                  ) {
                    arrayProductsId.push(item.id);
                  }
                }
              }
            } else {
              if (
                priceValue === "Desde $0" ||
                (priceValue === "Desde $100" && item.price >= 100) ||
                (priceValue === "Desde $200" && item.price >= 200) ||
                (priceValue === "Desde $500" && item.price >= 500) ||
                (priceValue === "Desde $1.000" && item.price >= 1000) ||
                (priceValue === "Desde $3.000" && item.price >= 3000) ||
                (priceValue === "Desde $5.000" && item.price >= 5000)
              ) {
                if (
                  progressValue === "Desde 0%" ||
                  (progressValue === "Desde 25%" && progressStatus >= 25) ||
                  (progressValue === "Desde 50%" && progressStatus >= 50) ||
                  (progressValue === "Desde 75%" && progressStatus >= 75)
                ) {
                  arrayProductsId.push(item.id);
                }
              }
            }
          }
        }
      });
  
      /// RECORRER MIS COMPRAS Y COMPARAR CON LOS IDS SELECCIONADOS PREVIAMENTE
      let buysJsonFiltered = [];
  
      for (let index = 0; index < arrayProductsId.length; index++) {
        for (let index2 = 0; index2 < products.length; index2++) {
          if (products[index2].id === arrayProductsId[index]) {
            buysJsonFiltered.push(products[index2]);
          }
        }
      }
  
      setArrayLength(buysJsonFiltered.length);
  
      setProductsFiltered(buysJsonFiltered);
    }
    
  }, [stateValue, categoryValue, priceValue, progressValue, products]);

  return (
    <React.Fragment>
      <BannerCategory title={textTitle} />
      <div className="product-section-page__top-info">
        <div className="text-left">
          <p className="product-section-page__title">{textDescription}</p>
        </div>
        <div className="text-right">
          <p className="product-section-page__total">
            Hay {arrayLength} {arrayLength === 1 ? "opcion" : "opciones"} para
            elegir
          </p>
        </div>
      </div>

      <div className="product-section-page__info--container">
        <Filter
          state={false}
          categories={true}
          prices={true}
          progress={true}
          priceId={priceValue}
          stateId={stateValue}
          progressId={progressValue}
          onState={setStateValue}
          onPrice={setPriceValue}
          onProgress={setProgressValue}
          categoryId={categoryValue}
          onStateCategory={setCategoryValue}
        />
        {arrayLength > 0 && (
          <ProductSectionContainer
            stateId={stateValue}
            categoryId={categoryValue}
            products={productsFiltered}
          />
        )}
        {arrayLength === 0 && (
          <h3 className="product-section-page__stock">
            No hay stock de productos con estas caracteristicas
          </h3>
        )}
      </div>

      {products && (<ScrollContainer
        items={products}
        title="Nuevos."
        scroll="700"
        verMasText="Ver más"
        verMasRef="/"
        cardType="ProductCard"
      />)
      }
    </React.Fragment>
  );
};

export default ProductSectionPage;
