import React from "react";

import BannerLegal from "../../shared/components/UIElements/BannerLegal";
import PartnersForm from "../components/PartnersPageForm";
import PartnersFormText from "../components/PartnersFormText";
import PartnersPageText from "../components/PartnersPageText";
import "./PartnersPage.css"


const partnersPage = () =>{

    return (
        <div>
            <BannerLegal title='Partners' />
            <PartnersPageText />
            <div className="partners-page__info--container">
                <PartnersFormText />
                <PartnersForm />
            </div>
            
        </div>
    );

};

export default partnersPage;