import React, { useEffect, useState, useContext } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useParams, useHistory } from "react-router-dom";
import * as Yup from "yup";

import BannerCategory from "../../../shared/components/UIElements/BannerCategory";
import Select from "../../../shared/components/FormElements/Select";
import Switch from "../../../shared/components/FormElements/Switch";
import GenericModal from "../../../shared/components/UIElements/GenericModal";
import Button from "../../../shared/components/FormElements/Button";
import Card from "../../../shared/components/UIElements/Card";
import LoadingSpinner from "../../../shared/components/UIElements/LoadingSpinner";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import { AuthContext } from "../../../shared/context/AuthContext";
import "./MainSlidersForm.css";

const HelpSchema = Yup.object().shape(
  {
    title: Yup.string().required("Requerido"),
    description: Yup.string().required("Requerido"),
    order: Yup.string()
      .oneOf(["1", "2", "3", "4", "5", "6"])
      .required("Requerido"),
    isExtLink: Yup.boolean(),
    extLink: Yup.string().when("intLink", {
      is: (intLink) => !intLink || intLink.length === 0,
      then: Yup.string()
        .matches(
          /^(www\.)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
          "Formato: www.***.com"
        )
        .required("Requerido"),
      otherwise: Yup.string(),
    }),
    intLink: Yup.string().when("extLink", {
      is: (extLink) => !extLink || extLink.length === 0,
      then: Yup.string().required("Requerido"),
      otherwise: Yup.string(),
    }),
    image: Yup.mixed(),
  },
  [["extLink", "intLink"]]
);

const UpdateMainSlider = () => {
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [loadedMainSlider, setLoadedMainSlider] = useState();
  const [isExtLinkValue, setIsExtLink] = useState(false);
  const mainsliderId = useParams().mainsliderId;
  const history = useHistory();

  useEffect(() => {
    const fetchMainSlider = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_API_URL}/api/mainsliders/${mainsliderId}`
        );
        setLoadedMainSlider(responseData.mainslider);
        setIsExtLink(responseData.mainslider.isExtLink);
      } catch (err) {}
    };
    fetchMainSlider();
  }, [sendRequest, mainsliderId]);

  if (isLoading) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  if (!loadedMainSlider && !error) {
    return (
      <div className="center">
        <Card>
          <h2>Could not find mainslider!</h2>
        </Card>
      </div>
    );
  }

  return (
    <React.Fragment>
      <GenericModal
        message={error}
        show={!!error}
        error={true}
        title={"Ocurrió un error"}
        onClear={clearError}
      />
      <BannerCategory title={`Editar Mainslider: ${loadedMainSlider.title}`} />
      <Formik
        initialValues={{
          title: loadedMainSlider.title,
          description: loadedMainSlider.description,
          order: loadedMainSlider.order,
          isExtLink: loadedMainSlider.isExtLink,
          intLink: loadedMainSlider.intLink,
          extLink: loadedMainSlider.extLink,
          image: loadedMainSlider.image,
          status: loadedMainSlider.status,
        }}
        validationSchema={HelpSchema}
        onSubmit={async (values) => {
          try {
            const formData = new FormData();
            formData.append("title", values.title);
            formData.append("description", values.description);
            formData.append("order", values.order);
            formData.append("isExtLink", values.isExtLink);
            formData.append("intLink", values.intLink);
            formData.append("extLink", values.extLink);
            formData.append("status", values.status);

            formData.append("image", values.image);
            await sendRequest(
              `${process.env.REACT_APP_API_URL}/api/mainsliders/${mainsliderId}`,
              "PATCH",
              formData,
              { Authorization: "Bearer " + auth.token }
            );
            history.push("/backend/mainsliders");
          } catch (err) {}
        }}
      >
        {(formProps) => (
          <div className="mainslider-form__background">
            <Card className="mainslider-form__card">
              <h2 className="mainslider-form__title--big">DATOS MAINSLIDER</h2>
              <Form className="mainslider-form">
                <div className="mainslider-form__content">
                  <div className="mainslider-form__input-block mainslider-form__input-block--grid">
                    <div className="mainslider-form__input-block mainslider-form__input-block--column">
                      <div className="mainslider-form__error-placeholder mainslider-form__error-placeholder--column">
                        <ErrorMessage
                          name="title"
                          component="div"
                          className="mainslider-form__validation-error"
                        />
                      </div>
                      <Field
                        type="text"
                        name="title"
                        placeholder="Titulo"
                        className="mainslider-form__form-input mainslider-form__form-input--inline"
                      />
                    </div>
                    <div className="mainslider-form__input-block mainslider-form__input-block--column">
                      <div className="mainslider-form__error-placeholder mainslider-form__error-placeholder--column">
                        <ErrorMessage
                          name="description"
                          component="div"
                          className="mainslider-form__validation-error"
                        />
                      </div>

                      <Field
                        type="textArea"
                        name="description"
                        placeholder="Descripcion"
                        className="mainslider-form__form-input mainslider-form__form-input--inline"
                      />
                    </div>
                  </div>

                  <div className="mainslider-form__input-block mainslider-form__input-block--grid">
                    <div className="mainslider-form__input-block mainslider-form__input-block--column">
                      <div className="mainslider-form__error-placeholder">
                        <ErrorMessage
                          name="order"
                          component="div"
                          className="mainslider-form__validation-error"
                        />
                      </div>
                      <Select
                        className="mainslider-form__form-input mainslider-form__form-input--inline"
                        name="order"
                      >
                        <option value="">Orden</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4 </option>
                        <option value="5">5 </option>
                        <option value="6">6 </option>
                      </Select>
                    </div>
                    <div className="mainslider-form__input-block mainslider-form__input-block--column">
                      <div className="mainslider-form__error-placeholder mainslider-form__error-placeholder--column">
                        <label>Link externo?</label>
                        <Field
                          name="isExtLink"
                          type="checkbox"
                          className="mainslider-form__form-input mainslider-form__form-input--inline"
                          onClick={() => {
                            setIsExtLink(!isExtLinkValue);
                            formProps.setFieldValue(
                              "isExtLink",
                              isExtLinkValue
                            );
                          }}
                        />
                      </div>
                      {isExtLinkValue === false ? (
                        <React.Fragment>
                          <ErrorMessage
                            name="intLink"
                            component="div"
                            className="mainslider-form__validation-error"
                          />
                          <Field
                            type="text"
                            name="intLink"
                            placeholder="Link interno"
                            className="mainslider-form__form-input mainslider-form__form-input--inline"
                          />
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <ErrorMessage
                            name="extLink"
                            component="div"
                            className="mainslider-form__validation-error"
                          />
                          <Field
                            type="text"
                            name="extLink"
                            placeholder="Link externo"
                            className="mainslider-form__form-input mainslider-form__form-input--inline"
                          />
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                  <div className="mainslider-form__input-block mainslider-form__input-block--grid">
                    <div className="mainslider-form__input-block mainslider-form__input-block--column">
                      <div className="mainslider-form__error-placeholder mainslider-form__error-placeholder--column">
                        <ErrorMessage
                          name="image"
                          component="div"
                          className="mainslider-form__validation-error"
                        />
                      </div>

                      <input
                        type="file"
                        name="image"
                        onChange={(event) => {
                          formProps.setFieldValue(
                            "image",
                            event.target.files[0]
                          );
                        }}
                      />
                    </div>
                    <div className="mainslider-form__error-placeholder mainslider-form__error-placeholder--column">
                      <label>Activo</label>
                      <Switch
                        name="status"
                        className="mainslider-form__form-input mainslider-form__form-input--inline"
                        defaultChecked={loadedMainSlider.status}
                      />
                    </div>
                  </div>
                  <div className="help-form__footer">
                    <div className="help-form__button-block--grid">
                      <Button
                        className="main-button--squared-bigger main-button--80-width"
                        type="submit"
                        status="active"
                      >
                        Editar
                      </Button>
                      <Button
                        to="/backend/mainsliders"
                        className="main-button--squared-bigger main-button--80-width mainslider-form__back-button"
                      >
                        Atrás
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            </Card>
          </div>
        )}
      </Formik>
    </React.Fragment>
  );
};
export default UpdateMainSlider;
